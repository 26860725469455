export function getIndicesOf(searchStr, content, caseSensitive, finalResponse) {
    let searchStrLen = searchStr.length;

    if (searchStrLen === 0) {
        return [];
    }

    let startIndex = 0,
        index,
        indices = [];
    if (!caseSensitive) {
        content = content.toLowerCase();
        searchStr = searchStr.toLowerCase();
    }
    const exist = finalResponse.find((it) => it.original.includes(searchStr))
    if (exist) {
        startIndex = exist.endOffset + 1
    }

    if ((index = content.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }

    return indices;
}

export function strToJson(str) {
    str = str.replace(/.*?(\{.*\}).*/s, '$1')
    // console.log(str);
    return JSON.parse(str);
}

export function applySegmentsToWords(text, segments) {
    const ranges = [];

    let last = 0;

    segments.forEach((segment) => {
        const { startOffset, endOffset, categoryObj, suggestion, corrected } = segment;
        if (startOffset > last) {
            ranges.push({
                text: text.slice(last, startOffset),
                matches: null,
            });
        }
        if (startOffset < last) {
            // subgroup exist / is subgroup
            // const endLastRange = ranges[ranges.length - 1];
            if (endOffset >  last) {
                ranges.push({
                    text: text.slice(last + 1, endOffset),
                    matches: {categoryObj, suggestion, corrected},
                });
                last = endOffset;
            }
        } else {
            ranges.push({
                text: text.slice(startOffset, endOffset),
                matches: {categoryObj, suggestion, corrected},
            });
            last = endOffset;
        }
    });

    if (last < text.length) {
        ranges.push({
            text: text.slice(last),
            matches: null,
        });
    }

    return ranges;
}

export function textToJson(text) {
    const regex = /\(\((.*?)\)\) \[\[(.*?)\]\] \+\+(.*?)\+\+/g;
    const matches = [...text.matchAll(regex)];

    const fixes = matches.map(match => {
        return {
            original: match[1],
            recommendation: match[2],
            category: match[3]
        };
    });

    return { fixes };
}

export function textToJson1(text) {
    const lines = text.split('\n');
    const fixes = [];
    for (let line of lines) {
        if (line.trim().startsWith('((')) {
            const startIndex = line.indexOf('((') + 2;
            const endIndex = line.indexOf('))');
            const content = line.substring(startIndex, endIndex).trim();
            const parts = content.split('[[');

            if (parts.length === 2) {
                const original = parts[0].trim();
                const recommendation = parts[1].split(']]')[0].trim();
                const categoryStartIndex = content.indexOf('++') + 2;
                const categoryEndIndex = content.lastIndexOf('++');
                const category = content.substring(categoryStartIndex, categoryEndIndex).trim();

                fixes.push({
                    original,
                    recommendation,
                    category
                });
            }
        }
    }

    return { fixes };
}
