import styles from './example.module.css'

export const Example = ({ text, value, onClick, autoHeight }) => {
    return (
        <>
            <div onClick={() => onClick(value)}>
                <p className={styles.exampleText + (autoHeight ? " h-auto" : "")}>{text}</p>
            </div>
        </>
    )
}
