import { useState } from 'react'
import { Stack, TextField } from '@fluentui/react'
import { Send28Filled } from '@fluentui/react-icons'

import styles from './question-input.module.css'
import './question-input.css'
import {IconButton, InputAdornment, useMediaQuery, useTheme} from "@mui/material";
import {AttachFile} from "@mui/icons-material";
import DriveChooseIndex from "../drive-choose/drive-choose-index";

export const QuestionInput = ({
  onSend,
  disabled,
  placeholder,
  clearOnSend, ...props
}) => {
  const [question, setQuestion] = useState('')
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return
    }

    onSend(question)

    if (clearOnSend) {
      setQuestion('')
    }
  }

  const onEnterPress = (ev) => {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.preventDefault()
      sendQuestion()
    }
  }

  const onQuestionChange = (ev, newValue) => {
    /*if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }*/

    setQuestion(newValue)
  }

  const sendQuestionDisabled = disabled || !question.trim()

    /**attach menu*/
    const [openAttach, setOpenAttach] = useState(null);
    const handleOpenAttachMenu = (event) => {
        setOpenAttach(event.currentTarget);
    };

  return (
      <div className={styles.questionContainer}>
        <Stack horizontal className={styles.questionInputContainer}>
          <TextField
              className={styles.questionInputTextArea}
              placeholder={ lessThanSmall ? (placeholder.slice(0, 15) + '...') : placeholder}
              multiline
              autoAdjustHeight
              rows={1}
              borderless
              value={question || ''}
              onChange={onQuestionChange}
              onKeyDown={onEnterPress}
              styles={{ overflow: 'hidden' }}
              onRenderPrefix={() =>
                  <div className="d-block position-relative">
                      <IconButton onClick={handleOpenAttachMenu}>
                          <AttachFile/>
                      </IconButton>
                      <DriveChooseIndex anchorEl={openAttach} handleOpen={setOpenAttach} answers={props.answers}/>
                  </div>
              }
          />
        </Stack>
        <div className={styles.questionInputButtonsContainer}>
          <div
              className={`${styles.questionInputSendButton} ${
                  sendQuestionDisabled ? styles.questionInputSendButtonDisabled : ''
              }`}
              aria-label="Ask question button"
              onClick={sendQuestion}
          >
            <Send28Filled primaryFill="rgba(115, 118, 225, 1)" />
          </div>
        </div>
      </div>
  )
}
