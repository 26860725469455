import { Example } from "./example";
import Lottie from "react-lottie";
import animationData from "./stars.json";

import styles from "./example.module.css";
import { connect } from "react-redux";

const EXAMPLES = [
  {
    text: "Give me the monthly payroll report of the whole organization",
    value: "Give me the monthly payroll report of the whole organization",
  },
  {
    text: "Can you give me a report of performance review of this year? ",
    value: "Can you give me a report of performance review of this year? ",
  },
  {
    text: "What's the status of the hiring process for the software engineers? ",
    value:
        "What's the status of the hiring process for the software engineers?",
  },
];

const ExampleList = (props) => {
  const { onExampleClicked, indexName } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    hover: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
      <div
          style={{
            color: props.chatTheme?.answerText || '',
            background: props.chatTheme?.answerBg || '',
          }}
          className={styles.example}
      >
        <div className={styles.initialTextContainer}>
          <div style={{ width: "40px", flex: "1" }}>
            <Lottie options={defaultOptions} height={40} width={40} />
          </div>
          {indexName === "smart-goals" || indexName === "feedback-assistant" ? (
              <div className={styles.initialText}>
                Hi, I'm Moodbit Copilot, your AI-HR agent. I'm here to help you with
                your human resources tasks, such as::
              </div>
          ) : indexName === "ccs-int-design" ? (
              <div className={styles.initialText}>
                <strong>Hello there!</strong> I'm Moodbit Copilot, your personal AI assistant
                designed to help you navigate your educational journey at CCS. I'm
                here to guide you towards your creative aspirations. <strong>Let's get
                started!</strong> Here are a few ideas to kick things off:
              </div>
          ) : (
              <div className={styles.initialText}>
                Hi, I'm Moodbit Copilot, your AI-HR agent. I'm here to assist you
                with any HR-related questions or requests you may have. Feel free to
                ask me anything or choose from our pre-built questions to quickly
                access the information and insights you need. Let's make HR
                management easier and more efficient together.
              </div>
          )}
        </div>
        <ul className={styles.examplesNavList}>
          {indexName === "sap-sql" ? (
              <div>
                <p className={styles.initialText + " ps-5 ms-2"}>
                  With this Resource you have access to the following SAP
                  information.
                </p>
                {[
                  { value: "Employee Information SAP" },
                  /*{value: "Employee Time information SAP"},*/
                  { value: "Employee Absents SAP" },
                  { value: "Forms for performance review in SAP" },
                  { value: "Competencies in Forms in SAP" },
                  { value: "Goals in Forms in SAP" },
                ].map((x) => (
                    <li>
                      <Example text={x.value} autoHeight={true} />
                    </li>
                ))}
              </div>
          ) : indexName === "smart-goals" ||
          indexName === "feedback-assistant" ? (
              <div>
                {[
                  { value: "Help me write my Smart Goal" },
                  { value: "Help me write a feedback" },
                ].map((x) => (
                    <li>
                      <Example
                          text={x.value}
                          autoHeight={true}
                          value={x.value}
                          onClick={onExampleClicked}
                      />
                    </li>
                ))}
              </div>
          ) : indexName === "ccs-int-design" ? (
              <div>
                {[
                  { value: "I want to change career paths" },
                  { value: "General Skill Enhancement" },
                  { value: "Learn more about College for Creative Studies" },
                ].map((x) => (
                    <li>
                      <Example
                          text={x.value}
                          autoHeight={true}
                          value={x.value}
                          onClick={onExampleClicked}
                      />
                    </li>
                ))}
                <div className={styles.initialText}>Feel free to ask me anything else. I'm excited to help you reach your full creative potential!</div>
              </div>
          ) : (
              EXAMPLES.map((x) => (
                  <li>
                    <Example
                        text={x.text}
                        value={x.value}
                        onClick={onExampleClicked}
                    />
                  </li>
              ))
          )}
        </ul>
      </div>
  );
};

const mapStateToProps = (state) => {
  console.log("state EXM", state)
  return {
    chatTheme: state.agentHrs.chatTheme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ExampleList);
