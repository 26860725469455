import axios from "axios";

function stringifyJsonWithoutNulls(obj) {
    return JSON.stringify(obj, (key, value) => {
        if (value === null || value === undefined) {
            return undefined;
        }
        return value;
    });
}

export const setChatHrService = async (req) => {
    /*const {data} = await axios.post(`${process.env.REACT_APP_BACK_HR_AGENT}/chat-hr`, req, {
        headers: {
            'Accept': 'text/event-stream'
        },
        responseType: 'stream'
    });*/
    const jsonString = stringifyJsonWithoutNulls(req);
    const response = await fetch(`${process.env.REACT_APP_BACK_HR_AGENT}/chat-hr`, {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: jsonString,
    });
    return response;
}

export const setSmartGoalsService = async (req) => {
    /*const {data} = await axios.post(`${process.env.REACT_APP_BACK_HR_AGENT}/chat-hr`, req, {
        headers: {
            'Accept': 'text/event-stream'
        },
        responseType: 'stream'
    });*/
    const jsonString = stringifyJsonWithoutNulls(req);
    const response = await fetch(`${process.env.REACT_APP_BACK_HR_AGENT}/get-smart-goals`, {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: jsonString,
    });
    return response;
}

export const setSAPAuthService = async (req) => {
    const jsonString = stringifyJsonWithoutNulls(req);
    const response = await fetch(`${process.env.REACT_APP_BACK_HR_AGENT}/get-smart-goals-json`, {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: jsonString,
    });
    return response.json();
}

export const setFeedbackSAPAuthService = async (req) => {
    const jsonString = stringifyJsonWithoutNulls(req);
    const response = await fetch(`${process.env.REACT_APP_BACK_HR_AGENT}/get-feedback-assistant-json`, {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: jsonString,
    });
    return response.json();
}

export const setSmartGoalsShortService = async (req) => {
    const jsonString = stringifyJsonWithoutNulls(req);
    const response = await fetch(`${process.env.REACT_APP_BACK_HR_AGENT}/get-smart-goals-short`, {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: jsonString,
    });
    return response;
}

export const setFeedbackAssistantService = async (req) => {
    const jsonString = stringifyJsonWithoutNulls(req);
    const response = await fetch(`${process.env.REACT_APP_BACK_HR_AGENT}/get-feedback-assistant`, {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: jsonString,
    });
    return response;
}

export const setChatHrOnedriveService = async (req) => {
    const jsonString = stringifyJsonWithoutNulls(req);
    const response = await fetch(`${process.env.REACT_APP_GET_GOOGLEDRIVE}/ms/chat_copilot`,{
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: jsonString
    });
    return response;
}

export const setChatHrGoogledriveService = async (req, token) => {
    let jsonString = stringifyJsonWithoutNulls(req);
    const response = await fetch(`${process.env.REACT_APP_GET_GOOGLEDRIVE}/google/chat_copilot`,{
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
        },
        method: "POST",
        body: jsonString
    });
    return response;
}

export const setChatHrSingleService = async (req) => {
    let jsonString = stringifyJsonWithoutNulls(req);
    const response = await fetch(`${process.env.REACT_APP_GET_GOOGLEDRIVE}/single/chat_copilot`,{
        headers: {
            "Content-Type": "application/json"
        },
        method: "POST",
        body: jsonString
    });
    return response;
}


export async function downloadVisual(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_HR_AGENT}/download-visual`, obj, {
        //responseType: 'blob'
    });
    return data;
}

export async function getInsight(question, answer, indexName) {

    const jsonString = stringifyJsonWithoutNulls({answer, question, indexName});
    const response = await fetch(`${process.env.REACT_APP_BACK_HR_AGENT}/get-insights`, {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: jsonString,
    });
    return response;

    /*const {data} = await axios.post(`${process.env.REACT_APP_BACK_HR_AGENT}/get-insights`, {answer});
    return data;*/
}

export async function getActionPlanService(insight) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_HR_AGENT}/get-action-plan`, {insight});
    return data;
}

export async function getFollowup(history, indexName) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_HR_AGENT}/get-followup`, {history, indexName});
    return data;
}

export async function downloadVisual2() {
    const token = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvNjE5ZTg1MWUtOGU3MC00M2ZjLWE5YTQtZDYwOWFmNWRiMDE4LyIsImlhdCI6MTY5NDE3NjI1MywibmJmIjoxNjk0MTc2MjUzLCJleHAiOjE2OTQxODAxNTMsImFpbyI6IkUyRmdZSGc5OVJPdnMrL2ZCVVpkQ2FxWlJ3VVBBQUE9IiwiYXBwaWQiOiJlYTQ5Y2ZiYi1lMDU3LTQzZjEtYTZkMC1hNjg3NGVlMGY4NmYiLCJhcHBpZGFjciI6IjEiLCJpZHAiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC82MTllODUxZS04ZTcwLTQzZmMtYTlhNC1kNjA5YWY1ZGIwMTgvIiwiaWR0eXAiOiJhcHAiLCJvaWQiOiI2NjkyM2I5Ny00ZDRmLTQ3ZGYtODdlMC0wNmM0ZDg5NzBiMDMiLCJyaCI6IjAuQVhnQUhvV2VZWENPX0VPcHBOWUpyMTJ3R0FrQUFBQUFBQUFBd0FBQUFBQUFBQUI0QUFBLiIsInN1YiI6IjY2OTIzYjk3LTRkNGYtNDdkZi04N2UwLTA2YzRkODk3MGIwMyIsInRpZCI6IjYxOWU4NTFlLThlNzAtNDNmYy1hOWE0LWQ2MDlhZjVkYjAxOCIsInV0aSI6Ik1mYmZiMmQ5ejB1dXdPemlNLXdOQUEiLCJ2ZXIiOiIxLjAiLCJ4bXNfY2MiOlsiQ1AxIl19.UxysPezBFxx5WYekZURcakcS8_x1QDj-WbYq9RACH0lM_FjLzysY1hv_RtfIbg8Lh27foS623jW6RgS9b7YeaWW4zrZJMRc8H9XR81-3M_2B7Hq93vimL04i1tQfMU18x2Q5Yzsw92QJu_o2A0lRJTKFmvbZGSkG-I2cYhzqflItJIIife0po6TNRMKldQROKJpN1qpFWvaOJJm_XqFHwo4DplpHggr2dLVsi577uK-MggXFxXAv4-ciWzO8ZhZcXmU9jtdBsz5enTa5wl0c6lMUuQ3hMxSjV6xRG8LhaRWiIMphmzYhup_ee7HHkJbhbVXcUnvqtNnC0wikHbJDMQ`;
    const {data} = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/d58bd78b-8c28-4a74-ba03-0c8def1b3747/reports/75aea719-1c91-4212-a27b-23861c3263aa/exports/MS9CbG9iSWRWMi01ZTZhZmJmZi01MmQ4LTQyY2MtYTFjNS0wY2MxOWY2N2Q3ODBFS1FRak0wdTkzajh0M0E0cGlSQmhDMnkxeVpTLg==/file`, {
        headers: {
            Authorization: token,
            'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
    });
    return data;
}

/*export const streamResponse = () => {
    const eventSource = new EventSource(`${process.env.REACT_APP_BACK_HR_AGENT}/chat-hr`);

    eventSource.onmessage = (event) => {
        const message = event.data;
        console.log('STREAM RESPONSE', message); // Aquí puedes procesar la respuesta SSE como desees
    };

    return () => {
        eventSource.close(); // Cierra la conexión SSE al desmontar el componente
    };
}*/

export const storeConversationMessage = async (req) => {
    const {data} = await axios.post(`${process.env.REACT_APP_AZURE_OPENAI_BACK}/api/store-messages-chathr-user`, req);
    return data;
}


export function getCitationFilePath(citation) {
    return `${process.env.REACT_APP_BACK_HR_AGENT}/content/${citation}`;
}

export function getCitationFilePathHR(citation, userId, containerName) {
    // return `${process.env.REACT_APP_BACK_HR_AGENT}/content-hr/${citation}?analytics_user_id=${userId}`;
    //return `${process.env.REACT_APP_BACK_HR_AGENT}/content-hr/${citation}?analytics_user_id=content-hr`;
    return `${process.env.REACT_APP_BACK_HR_AGENT}/content-hr/${citation}?analytics_user_id=${containerName}`;
    // return `${process.env.REACT_APP_BACK_HR_AGENT}/content-hr/${citation}?analytics_user_id=csv-cotent-hr`;
}

export async function sendEmailService(req) {
    const {data} = await axios.post(`${process.env.REACT_APP_SEND_EMAIL}/api/sendEmail`, req);
    return data;
}

export async function updateRateService(req) {
    /**Todo modify endpoint*/
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_HR_AGENT}/rate-answer`, req);
    return data;
}
/** grammar feedbacks**/



export async function getGrammarFeedbackService(content, categories) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_HR_AGENT}/get-feedback-grammar`, {content, categories});
    return data;
}

export async function getGrammarFeedbackStreamService(content, categories) {
    const jsonString = stringifyJsonWithoutNulls({content, categories});
    const response = await fetch(`${process.env.REACT_APP_BACK_HR_AGENT}/get-feedback-grammar`, {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: jsonString,
    });
    return response;
}

export async function getGrammarGoalStreamService(content) {
    const jsonString = stringifyJsonWithoutNulls({content});
    const response = await fetch(`${process.env.REACT_APP_BACK_HR_AGENT}/get-goal-grammar`, {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: jsonString,
    });
    return response;
}
