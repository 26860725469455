import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
    styled,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useState} from "react";
import {connect} from "react-redux";
import {useAwsGuard} from "../../guards/aws-guard";
import {useAwsPostAIGuard} from "../../guards/aws-post-ai-guard";

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const BillingAwsIndex = (props) => {
    const [expanded, setExpanded] = useState(false);
    const [expandedPostAi, setExpandedPostAi] = useState(false);
    const [hasAws, setHasAws] = useState(useAwsGuard())
    const [hasAwsPostAi, setHasAwsPostAi] = useState(useAwsPostAIGuard())

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (<>
        <Card>
            <CardHeader title="Your AWS Subscriptions"/>
            <CardContent>
                {/**Copilot AWS*/}
                {props.awsSubscription && <div className="w-100">
                    <p>Moodbit Copilot</p>
                    <Button variant="contained"
                            href="https://aws.amazon.com/marketplace/pp/prodview-4nle42fgoqen4?sr=0-3&ref_=beagle&applicationId=AWSMPContessa"
                            target="_blank" color="warning" className="btn-primary">
                        See in MarketPlace
                    </Button>
                    {!hasAws && <Button variant="contained"
                                        href="mailto:info@mymoodbit.com?subject=Enable Moodbit Copilot for my account - AWS user&body=Hi, I already made my payment for Moodbit Copilot from AWS MK, I need you to enable this functionality for this user"
                                       target="_blank" color="primary" className="btn-primary">
                    Contact Us
                </Button>}
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon/>
                    </ExpandMore>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>
                                <b>Product
                                    Code: </b>{JSON.parse(props.awsSubscription?.entitlement || "{}")?.Entitlements?.[0]?.ProductCode || ''}
                            </Typography>
                            <Typography paragraph>
                                <b>User Email: </b>{props.awsSubscription?.contactEmail}
                            </Typography>
                            <Typography paragraph>
                                <b>Expiration
                                    Date: </b>{JSON.parse(props.awsSubscription?.entitlement || "{}")?.Entitlements?.[0]?.ExpirationDate || ''}
                            </Typography>
                        </CardContent>
                    </Collapse>
                </div>}
                {/**Post AI AWS*/}
                {props.awsPostAiSubscription && <div>
                    <div className="d-flex align-items-center">
                        <p className="m-0">
                            Moodbit Content Post AI
                        </p>
                        <Button variant="contained"
                                href="https://aws.amazon.com/marketplace/pp/prodview-jqwawcyh7ozre?sr=0-2&ref_=beagle&applicationId=AWSMPContessa"
                                target="_blank" color="warning" className="btn-primary">
                            See in MarketPlace
                        </Button>
                        {!hasAwsPostAi && <Button variant="contained"
                                            href="mailto:info@mymoodbit.com?subject=Enable Moodbit Content Post AI for my account - AWS user&body=Hi, I already made my payment for Moodbit Content Post AI from AWS MK, I need you to enable this functionality for this user"
                                            target="_blank" color="primary" className="btn-primary">
                            Contact Us
                        </Button>}
                        <ExpandMore
                            expand={expandedPostAi}
                            onClick={() => setExpandedPostAi(!expandedPostAi)}
                            aria-expanded={expandedPostAi}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon/>
                        </ExpandMore>
                    </div>
                    <Collapse in={expandedPostAi} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>
                                <b>Product
                                    Code: </b>{JSON.parse(props.awsPostAiSubscription?.entitlement || "{}")?.Entitlements?.[0]?.ProductCode || ''}
                            </Typography>

                            <Typography paragraph>
                                <b>User Email: </b>{props.awsPostAiSubscription?.contactEmail}
                            </Typography>

                            <Typography paragraph>
                                <b>Expiration
                                    Date: </b>{JSON.parse(props.awsPostAiSubscription?.entitlement || "{}")?.Entitlements?.[0]?.ExpirationDate || ''}
                            </Typography>
                        </CardContent>
                    </Collapse>
                </div>}
            </CardContent>
        </Card>
    </>)
}
// Map state to props;
const mapStateToProps = (state) => {
    return {
        awsSubscription: state.users.user.all_aws_live_subscriptions,
        awsPostAiSubscription: state.users.user.all_aws_post_ai_live_subscriptions,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingAwsIndex);
