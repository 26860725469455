import {useTranslation} from "react-i18next";
import Billing from "./billing-stripe-index";
import {useEffect, useState} from "react";
import {useAwsGuard} from "../../guards/aws-guard";
import BillingAwsIndex from "./billing-aws-index";
import {CircularProgress, useMediaQuery, useTheme} from "@mui/material";
import {useTeamsDatachatGuard} from "../../guards/teams-datachat-guard";
import {useTeamsPostAIGuard} from "../../guards/teams-postai-guard";
import {getBotAccessUserThunk} from "../../redux/users/users.thunk";
import {connect} from "react-redux";
import * as moment from "moment";
import {useAwsCopilotJustPaidGuard} from "../../guards/aws-copilot-just-paid-guard";
import {useAwsPostAIJustPaidGuard} from "../../guards/aws-post-ai-just-paid-guard";

const BillingIndex = (props) => {
    const {t} = useTranslation()

    const theme = useTheme();

    const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
    const smallToMid = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const [loading, setLoading] = useState(false)
    const [showBilling, setShowBilling] = useState(true)

    const [hasAws, setHasAws] = useState(useAwsCopilotJustPaidGuard())
    const [hasAwsPostAI, setHasAwsPostAI] = useState(useAwsPostAIJustPaidGuard())

    const [isTeamsDatachatUser, setIsTeamsDatachatUser] = useState(useTeamsDatachatGuard())
    const [isTeamsPostaiUser, setIsTeamsPostaiUser] = useState(useTeamsPostAIGuard())

    useEffect(() => {
        const func = (async () => {
            try {
                setLoading(true)
                await props.getBotAccessUser(props.user.email)
                console.log("ACCESS BOT TEAMS", props)

                let notUsed = true;
                if (isTeamsDatachatUser) {
                    /** Check if datachat bot was used in the last 10 minutes**/
                    const currentDate = moment();
                    const lastUsedDate = moment(props.botAccessUser?.teamsDatachatUser?.lastConnection);
                    notUsed = currentDate.diff(lastUsedDate, 'minutes') > 10;
                }

                if (isTeamsPostaiUser) {
                    /** Check if teams postai bot was used in the last 10 minutes**/
                    const currentDate = moment();
                    const lastUsedDate = moment(props.botAccessUser?.teamPostAIUser?.lastConnection);
                    notUsed = currentDate.diff(lastUsedDate, 'minutes') > 10;
                    console.log("POSTAI TEAMS", currentDate, lastUsedDate, notUsed)
                }
                setShowBilling(notUsed);

            } catch (e) {
                console.log("ERROR get posts", e)
            } finally {
                setLoading(false)
            }
        })
        func();
    }, [])


    // return (hasAws ? <BillingAwsIndex/> : <Billing t={t}/>)
    return (
        <>
            {loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                !lessThanSmall || showBilling ?
                    hasAws || hasAwsPostAI ? <BillingAwsIndex/> : <Billing t={t}/>
                    :
                    <p className="mt-5 mx-3 text-center text-secondary">This feature is optimized for use on desktop devices. For
                        the best experience, we recommend accessing from a computer.</p>
            }
        </>
    )
}
const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        botAccessUser: state.users.bot_access_user,
        user: state.users.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBotAccessUser: (email) => dispatch(getBotAccessUserThunk(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingIndex);
