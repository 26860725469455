import { useEffect, useMemo, useState } from "react";
import { Stack } from "@fluentui/react";

import styles from './answer.module.css'

import { parseAnswerToHtml, parseFollowUp, parseInsightsToHtml } from "./answer-parser";
import { AnswerIcon } from "./answer-icon";
import { getCitationFilePathHR } from "../../../../../services/agent-hr.service";
import { connect } from "react-redux";
import { SendEmailButton } from "../send-email-button/send-email-button";
import { Box, Chip, IconButton } from "@mui/material";
import {
    Assistant,
    BarChart,
    PictureAsPdf,
    ThumbDownAlt,
    ThumbDownAltOutlined,
    ThumbUpAlt,
    ThumbUpAltOutlined
} from "@mui/icons-material";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import './answer.css';
import PbiQaIndex from "../pbi-qa/pbi-qa-index";
import { getY } from "../../helpers/pdf";
import AnswerLoading from "./answer-loading";
import FollowupLoading from "./followup-loading";
import AnswerInfo from "./answer-info";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ChartsPanel } from "../../../agent-hr/components/graphics/ChartsPanel";
import { ChartMaximized } from "../../../agent-hr/components/graphics/ChartMaximized";
import { parseAnswer } from "../../../../../helpers/parseAnswer"
import { exportAnswerToDocument, exportTableToSpreadsheet } from "../../../../../helpers/export"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Answer = (props) => {
    // console.log(props.makeApiRequest)
    // console.log("ANS", document.querySelector(".answer-container"));
    const {
        answer,
        isSelected,
        onCitationClicked,
        onThoughtProcessClicked,
        onSupportingContentClicked,
        onFollowupQuestionClicked,
        showFollowupQuestions,
        newFollowup,
        index,
        isLoadingInsight,
        isLoadingFollowup,
        toggleActionPlan,
        updateRateAnswer
    } = props

    /*const [width, setWidth] = useState(0);
    useEffect(() => {
        const content = document.querySelector(".answer-container");
        console.log("content", content.clientWidth, content.clientHeight);
        setWidth(content?.clientWidth);
    },[])

    useEffect(() => {
        console.log("WIDTH", width, showFollowupQuestions);
        if (!showFollowupQuestions) {
            const container = document.querySelector(".answer-container");
            container.styles.width = width;
            console.log("WIDTH SW", width);
        }
    }, showFollowupQuestions);*/

    const [showGraphic, setShowGraphic] = useState(false);
    const [isChartMaximized, setIsChartMaximized] = useState(false);

    const parsedAnswer = useMemo(
        () => parseAnswerToHtml(answer.answer, onCitationClicked, props.user, props.containerName, answer, props.chatTheme),
        [answer]
    )

    const followupQuestions = parseFollowUp(answer);
    // const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const sanitizedAnswerHtml = parsedAnswer.answerHtml;
    let parsedInsights = ''
    if (answer.insight)
        parsedInsights = parseInsightsToHtml(answer.insight)

    const printPDF = async () => {
        const doc = new jsPDF();
        doc.setFontSize(10);
        const div = document.createElement('div');
        div.style.whiteSpace = "pre-line";
        div.innerHTML = sanitizedAnswerHtml;

        const table = div.querySelector('table');
        if (table) {
            const contentTable = div.querySelector('.table-content');
            let prevElements = [];
            let currentElement = contentTable.previousElementSibling;
            while (currentElement !== null) {
                prevElements.unshift(currentElement);
                currentElement = currentElement.previousElementSibling;
            }

            let nextElements = [];
            currentElement = contentTable.nextElementSibling;
            while (currentElement !== null) {
                nextElements.unshift(currentElement);
                currentElement = currentElement.nextElementSibling;
            }

            //join all in a pdf
            // await doc.html(prevElements[0],{margin: [10, 10, 10, 10], autoPaging: 'text', x: 10, y: 10, width: 150, windowWidth: 500});

            let currentY = getY(doc, prevElements.length > 0 ? prevElements[0].outerHTML : '', 15) + 10;

            doc.autoTable({ html: table, startY: currentY + 10 });

            currentY = getY(doc, nextElements.length > 0 ? nextElements[0].outerHTML : '', doc.autoTable.previous.finalY + 10) + 10;
            // await doc.html(nextElements[0],{margin: [10, 10, 10, 10], autoPaging: 'text', width: 150, windowWidth: 500,x: 10, y: doc.autoTable.previous.finalY + 10});
            doc.save('document');
        } else {
            doc.html(div, {
                async callback(doc) {
                    await doc.save('document');
                    div.classList.remove('page-size');
                },
                margin: [10, 10, 10, 10],
                autoPaging: 'text',
                x: 15,
                y: 15,
                width: 150, //target width in the PDF document
                windowWidth: 500 //window width in CSS pixels
            });
        }
    }

    const handleMaximizeChart = () => {
        setIsChartMaximized(true);
    };

    const handleCloseChartMaximized = () => {
        setIsChartMaximized(false);
    };

    console.log("***ANSWER***", props.answer);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    console.log("SEARCH SUGGESTIONS:", props.answer.searchSuggestions);

    const parsedAnswer2 = parseAnswer(props.answer.answer);

    const handleExportToDocument = async () => {
        await exportAnswerToDocument(parsedAnswer2);
    }

    const handleExportToSpreadsheet = async (index) => {
        const table = parsedAnswer2[index].data;
        await exportTableToSpreadsheet(table);
    }

    const handleCopytoClipboard = () => {
        navigator.clipboard.writeText(props.answer.answer);
    }

    return (
        <div>

            {props.answer.searchSuggestions?.data && <div style={{ color: '#0000b0', fontSize: '14px' }}>
                {/* Searching:*/}
            </div>}
            {/*StatusName: {props.something.statusName}
                <br/>
                Result: {props.something.result}*/}
            {
                /*
                props.something.map(value =>
                    <Stack.Item grow style={{background: '#d1dbfa',
                        borderRadius: '5px', marginBottom:'5px', color:'#0000b0', padding:'5px 15px', fontSize:'14px'}} >
                        <SearchIcon/>
                        {value.statusName}
                        <br/>
                        {value.result}
                    </Stack.Item>
                )
                */

                props.answer.searchSuggestions?.isLoading > 0 ? props.answer.searchSuggestions.data.map(value =>
                    <Stack.Item grow style={{
                        background: '#d1dbfa',
                        borderRadius: '5px', marginBottom: '5px', color: '#0000b0', padding: '5px 15px', fontSize: '14px'
                    }} >
                        <SearchIcon />
                        {value.statusName}
                        <br />
                        {value.result}
                    </Stack.Item>
                )
                    :
                    props.answer.searchSuggestions?.data.map(value =>
                        <Stack.Item grow style={{
                            background: '#d1dbfa',
                            borderRadius: '5px', marginBottom: '5px', color: '#0000b0', padding: '5px 15px', fontSize: '14px'
                        }} >
                            <SearchIcon />
                            {value.statusName}
                            <br />
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handleClose}>{value.result}</MenuItem>
                            </Menu>
                        </Stack.Item>
                    )
            }


            <Stack style={{
                background: props.chatTheme.answerBg,
                color: props.chatTheme.answerText,
            }}
                className={`${styles.answerContainer} ${isSelected && styles.selected}`}
                id={`answer-container-${index}`}
                verticalAlign="space-between">
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        <AnswerIcon chatTheme={props.chatTheme} />
                        <div>
                            <IconButton
                                title="Export to document"
                                aria-label="Export to document"
                                onClick={() => handleExportToDocument()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><defs><linearGradient id="vscodeIconsFileTypeWord0" x1="4.494" x2="13.832" y1="-1712.086" y2="-1695.914" gradientTransform="translate(0 1720)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#2368c4" /><stop offset=".5" stop-color="#1a5dbe" /><stop offset="1" stop-color="#1146ac" /></linearGradient></defs><path fill="#41a5ee" d="M28.806 3H9.705a1.19 1.19 0 0 0-1.193 1.191V9.5l11.069 3.25L30 9.5V4.191A1.19 1.19 0 0 0 28.806 3" /><path fill="#2b7cd3" d="M30 9.5H8.512V16l11.069 1.95L30 16Z" /><path fill="#185abd" d="M8.512 16v6.5l10.418 1.3L30 22.5V16Z" /><path fill="#103f91" d="M9.705 29h19.1A1.19 1.19 0 0 0 30 27.809V22.5H8.512v5.309A1.19 1.19 0 0 0 9.705 29" /><path d="M16.434 8.2H8.512v16.25h7.922a1.2 1.2 0 0 0 1.194-1.191V9.391A1.2 1.2 0 0 0 16.434 8.2" opacity="0.1" /><path d="M15.783 8.85H8.512V25.1h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191" opacity="0.2" /><path d="M15.783 8.85H8.512V23.8h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191" opacity="0.2" /><path d="M15.132 8.85h-6.62V23.8h6.62a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191" opacity="0.2" /><path fill="url(#vscodeIconsFileTypeWord0)" d="M3.194 8.85h11.938a1.193 1.193 0 0 1 1.194 1.191v11.918a1.193 1.193 0 0 1-1.194 1.191H3.194A1.19 1.19 0 0 1 2 21.959V10.041A1.19 1.19 0 0 1 3.194 8.85" /><path fill="#fff" d="M6.9 17.988q.035.276.046.481h.028q.015-.195.065-.47c.05-.275.062-.338.089-.465l1.255-5.407h1.624l1.3 5.326a8 8 0 0 1 .162 1h.022a8 8 0 0 1 .135-.975l1.039-5.358h1.477l-1.824 7.748h-1.727l-1.237-5.126q-.054-.222-.122-.578t-.084-.52h-.021q-.021.189-.084.561t-.1.552L7.78 19.871H6.024L4.19 12.127h1.5l1.131 5.418a5 5 0 0 1 .079.443" /></svg>
                            </IconButton>
                            <IconButton
                                title="Copy to clipboard"
                                aria-label="Copy to clipboard"
                                onClick={() => handleCopytoClipboard()}>
                                <ContentCopyIcon />
                            </IconButton>
                            <SendEmailButton answer={answer} index={index} content={sanitizedAnswerHtml}
                                citations={parsedAnswer.citations} user={props.user}
                                chatTheme={props.chatTheme} />
                            <IconButton
                                title="Show thought process"
                                ariaLabel="Show thought process"
                                onClick={() => printPDF()}>
                                <PictureAsPdf style={{
                                    color: props.chatTheme.iconColor
                                }} />
                            </IconButton>
                            {/*<IconButton
                            title="Show QA report"
                            index={props.index}
                            ariaLabel="Show QA report"
                            onClick={() => props.onSetShowQa()}>
                            <BarChart/>
                        </IconButton>*/}
                        </div>
                        {/*<div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        />
                    </div>*/}
                    </Stack>
                </Stack.Item>

                {/*answer.isPbi ?
                <Stack.Item grow>
                    <div id={`content-${props.index}`} className={`${styles.answerText} ${answer.finish ? '' : styles.answerTextCursor}`}>
                        <PbiQaIndex answers={props.answers}
                                    index={index}
                                    showFollowupQuestions={showFollowupQuestions}
                                    query={answer.qnaQuery}
                        question={props.question}
                        setClose={props.setClose}/>
                    </div>
                </Stack.Item> :*/}

                {/* Final message div */}
                <Stack.Item grow>
                    <div id={`content-${props.index}`}
                        className={`${styles.answerText} ${answer.finish ? '' : styles.answerTextCursor}`}
                        dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }} />

                </Stack.Item>

                {!!parsedAnswer.citations.length && answer.finish && (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>Citations:</span>
                            {parsedAnswer.citations.map((x, i) => {
                                const path = getCitationFilePathHR(x, props.user.id, answer.containerName);
                                if (answer.data_points && answer.data_points.some(item => item.title === x))
                                    return (
                                        <span>
                                            <a key={i} className={styles.citation} title={x}
                                                onClick={() => onCitationClicked(path, x)}>
                                                {`${++i}. ${x}`}
                                            </a>
                                            {/*<a key={-i} className={styles.citation} title={'See more...'}
                                    onClick={() => props.makeApiRequest('Please answer my previous question in more detail.')}>
                                        {'See more...'}
                                    </a>}
                                    <br></br>*/}
                                        </span>
                                    );
                            })}
                        </Stack>
                    </Stack.Item>
                )}
                {
                    parsedAnswer2.map((value, index) => {
                        if (value.type === "table") {
                            return <div>
                                <IconButton
                                    title="Export table"
                                    aria-label="Export table"
                                    onClick={() => handleExportToSpreadsheet(index)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><defs><linearGradient id="vscodeIconsFileTypeExcel0" x1="4.494" x2="13.832" y1="-2092.086" y2="-2075.914" gradientTransform="translate(0 2100)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#18884f" /><stop offset=".5" stop-color="#117e43" /><stop offset="1" stop-color="#0b6631" /></linearGradient></defs><path fill="#185c37" d="M19.581 15.35L8.512 13.4v14.409A1.19 1.19 0 0 0 9.705 29h19.1A1.19 1.19 0 0 0 30 27.809V22.5Z" /><path fill="#21a366" d="M19.581 3H9.705a1.19 1.19 0 0 0-1.193 1.191V9.5L19.581 16l5.861 1.95L30 16V9.5Z" /><path fill="#107c41" d="M8.512 9.5h11.069V16H8.512Z" /><path d="M16.434 8.2H8.512v16.25h7.922a1.2 1.2 0 0 0 1.194-1.191V9.391A1.2 1.2 0 0 0 16.434 8.2" opacity="0.1" /><path d="M15.783 8.85H8.512V25.1h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191" opacity="0.2" /><path d="M15.783 8.85H8.512V23.8h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191" opacity="0.2" /><path d="M15.132 8.85h-6.62V23.8h6.62a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191" opacity="0.2" /><path fill="url(#vscodeIconsFileTypeExcel0)" d="M3.194 8.85h11.938a1.193 1.193 0 0 1 1.194 1.191v11.918a1.193 1.193 0 0 1-1.194 1.191H3.194A1.19 1.19 0 0 1 2 21.959V10.041A1.19 1.19 0 0 1 3.194 8.85" /><path fill="#fff" d="m5.7 19.873l2.511-3.884l-2.3-3.862h1.847L9.013 14.6c.116.234.2.408.238.524h.017q.123-.281.26-.546l1.342-2.447h1.7l-2.359 3.84l2.419 3.905h-1.809l-1.45-2.711A2.4 2.4 0 0 1 9.2 16.8h-.024a1.7 1.7 0 0 1-.168.351l-1.493 2.722Z" /><path fill="#33c481" d="M28.806 3h-9.225v6.5H30V4.191A1.19 1.19 0 0 0 28.806 3" /><path fill="#107c41" d="M19.581 16H30v6.5H19.581Z" /></svg>
                                </IconButton>
                            </div>;
                        }
                        return <></>
                    })
                }
                <Stack.Item grow>
                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                        {/** ANSWER-INFO**/}
                        {answer.statistics && <AnswerInfo statistics={answer.statistics} />}

                        {/** RATE ICONS*/}
                        {/* TODO enable for all users? */}
                        {props.user?.copilotAccess?.rateAnswer && answer.finish && answer.rate === undefined &&
                            <div className="">
                                <ThumbUpAltOutlined onClick={() => updateRateAnswer(true)} style={{ cursor: 'pointer', fontSize: 15 }} className="me-2" />
                                <ThumbDownAltOutlined onClick={() => updateRateAnswer(false)} style={{ cursor: 'pointer', fontSize: 15 }} />
                            </div>}
                    </Stack>
                </Stack.Item>
            </Stack>

            {/* GENERATE CHART */}
            {showGraphic && (
                <Box
                    sx={{
                        bgcolor: "#e8ebfa",
                        maxHeight: "60vh",
                        borderRadius: "10px",
                        display: "flex",
                        paddingTop: "20px",
                        paddingX: "10px",
                        position: "relative",
                        width: "auto",
                    }}
                >
                    <ChartsPanel
                        jsonChartData={{ ...(answer.graphic), sql_json: answer.sql_json }}
                        onClose={() => {
                            setShowGraphic(false);
                        }}
                        onClickChart={handleMaximizeChart}
                        sx={{
                            top: "20px",
                        }}
                    />
                    {isChartMaximized && (
                        <ChartMaximized
                            jsonChartData={{ ...(answer.graphic), sql_json: answer.sql_json }}
                            onClose={handleCloseChartMaximized}
                        />
                    )}
                </Box>
            )}

            {/** INSIGHTS**/}
            {(answer.insight || isLoadingInsight) &&
                <Stack.Item grow style={{
                    background: props.chatTheme.answerBg,
                    color: props.chatTheme.answerText,
                }}
                    className={styles.insights}>

                    {!answer.insight && <AnswerLoading />}

                    {answer.insight && <h4 className="text-primary"><b>INSIGHTS</b></h4>}
                    <div
                        className={`${styles.answerText} ${styles.insightText} ${answer.finishInsight ? '' : styles.answerTextCursor}`}
                        dangerouslySetInnerHTML={{ __html: parsedInsights }}>
                    </div>
                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                        {/** ANSWER-INFO**/}
                        {answer.insightObject && <AnswerInfo statistics={answer.insightObject} />}
                    </Stack>
                    {/**add button*/}
                    {false && answer.insight && answer.finishInsight && <div className="w-100">
                        <Chip icon={<Assistant style={{
                            color: props.chatTheme.followupText
                        }} />} label="Action Plan" style={{
                            cursor: "pointer",
                            color: props.chatTheme.followupText,
                            background: props.chatTheme.followupBg
                        }} onClick={() => toggleActionPlan(answer)} />
                    </div>}
                </Stack.Item>}

            {/** FOLLOWUP QUUESTIONS**/}
            {isLoadingFollowup && <FollowupLoading />}
            {!!followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item className="mt-3">
                    <Stack horizontal wrap
                        className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`}
                        tokens={{ childrenGap: 6 }}>
                        <div className="w-100">
                            <Chip icon={<Assistant style={{
                                color: props.chatTheme.followupText
                            }} />} label="Get More relevant response" style={{
                                cursor: "pointer",
                                color: props.chatTheme.followupText,
                                background: props.chatTheme.followupBg
                            }} onClick={newFollowup} />
                        </div>
                        {/*<span className={styles.followupQuestionLearnMore}>What do you want to ask now? </span>*/}
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} style={{
                                    color: props.chatTheme.followupBg,
                                    borderColor: props.chatTheme.followupBg
                                }} className={styles.followupQuestion} title={x}
                                    onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

        </div>
    );
};


const mapStateToProps = (state) => {
    console.log('state app:', state)
    return {
        user: state.users.user,
        containerName: state.agentHrs.container,
        chatTheme: state.agentHrs.chatTheme
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Answer)
