import {List, ListItem, Avatar, ListItemIcon, ListItemText} from "@mui/material";
import {Check} from "@mui/icons-material";

const FinalResult = (props) => {
    const {finalResult, success, categories} = props;
    const getColor = (res) => {
        let color = "#cecece";
        categories.forEach((cat) => {
            if (cat.name === res.category) {
                color = cat.color;
            }
        })
        return color;
    }

    return (
        <>
            <List dense={true}>
                {finalResult?.map((res, index) =>
                    <ListItem key={index}>
                        <ListItemIcon>
                            <Check style={{
                                color: getColor(res)
                            }}/>
                        </ListItemIcon>
                        <ListItemText primary={res.category} secondary={res.description}/>
                    </ListItem>)}
            </List>
        </>
    )
}

export default FinalResult;
