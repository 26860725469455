import {Stack} from "@fluentui/react";
import {Alert, AlertTitle, Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Check, Close, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {useState} from "react";

const SuccessAlertSap = (props) => {
    const { msgSAP, setMsgSAP} = props;
    const [openDetailsSAP, setOpenDetailsSAP] = useState(false);

    return (
        msgSAP !== null && <Stack.Item>
        <Alert severity="success" action={
            <div className="d-flex">
                <IconButton onClick={() => setOpenDetailsSAP(!openDetailsSAP)}>{openDetailsSAP ?
                    <KeyboardArrowUp/> : <KeyboardArrowDown/>}</IconButton>
                <IconButton onClick={() => setMsgSAP(null)}><Close/></IconButton>
            </div>
        }>
            <AlertTitle>{msgSAP.title}</AlertTitle>
            {msgSAP.subtitle}
        </Alert>
        <Collapse in={openDetailsSAP} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {msgSAP.details?.map((item) =>
                    <ListItemButton sx={{pl: 4}}>
                        <ListItemIcon>
                            <Check color="success"/>
                        </ListItemIcon>
                        <ListItemText primary={item.title} secondary={item.description}/>
                    </ListItemButton>)}
            </List>
        </Collapse>
    </Stack.Item>
    )
}

export default SuccessAlertSap;
