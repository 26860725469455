import {Avatar, Box, Button, CircularProgress, styled, TablePagination} from "@mui/material";

import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import SurveySlackCardItem from "./survey-card-item";
import SurveyTableSearch from "../survey-table-search";
import {useTranslation} from "react-i18next";
import Lottie from 'react-lottie'
import animationData from '../stars.json'
import {usePostAiIntegrationsGuard} from "../../../../guards/post-ai-integrations-guard";
import EditPanel from "../EditPanel";
import PostShow from "../post-show/post-show";
import {connect} from "react-redux";
import {getActiveSchedule, nextPublishDate} from "../../../../helpers/region";
import * as moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, {Draggable} from "@fullcalendar/interaction";
import PlatformIcon from "../../agent-post-month/layouts/platform-icon";
import {
    deleteScheduleThunk,
    getAllPostaisWithScheduleThunk,
    getSinglePostaiWithScheduleThunk
} from "../../../../redux/post-ai/post-ai.thunk";
import {
    addSchedulePostAIService, deleteScheduleWebhookService,
    newTopicPostMonthService, setNewScheduleService,
    updateSingleMonthPostService,
    updateSinglePostaiService
} from "../../../../services/agent-post-ai.service";
import {toastr} from "react-redux-toastr";
import momentTz from "moment-timezone";

const CustomScrollContent = styled('div')(({theme}) => ({
    overflowX: "auto",
    [theme.breakpoints.up('xs')]: {
        maxWidth: "calc(100% - 5px)",
    },
    [theme.breakpoints.up('sm')]: {
        maxWidth: "calc(100% - 55px)",
    }

}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator, filter) {
    array = array.filter((el) => {
        const include = el.title.toLowerCase().includes(filter.toLowerCase());
        return filter === '' || include;
    });

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const SurveySlackCard = (props) => {
    const {status, surveys} = props;
    const [order, setOrder] = useState('desc');
    const [filter, setFilter] = useState('');
    const [orderBy, setOrderBy] = useState('updateAt');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
    const [editData, setEditData] = useState(0);
    const [postaiIntegration, setPostaiIntegration] = useState(usePostAiIntegrationsGuard());


    const {t} = useTranslation();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        hover: true,
        animationData: animationData,
        rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        },
      }

    const handleOpenEditPanel = (data) => {
        setEditData(data);
        setIsEditPanelOpen(true);
    }

    const handleCloseEditPanel = () => {
        setIsEditPanelOpen(false);
    }

    function groupByDay(data) {
        return data.reduce((acc, item) => {
            const schedule = getActiveSchedule(item["analytics_post_ai_schedules"])
            const dateKey = nextPublishDate(schedule)
            // const dateKey = item.postDate.split(' ')[0];
            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }
            acc[dateKey].push(item);
            return acc;
        }, []);
    }

    /**calendar*/
    const getPostInfo = (postId) => {
        return surveys.find((post) => post?.id === postId);
    }

    function toCalendar(data) {
        let newData = data.filter((item, index) => {
            let schedule = getActiveSchedule(item.analytics_post_ai_schedules);
            return schedule && schedule?.date !== null;
        })
        newData = newData.map((item) => {
            let schedule = getActiveSchedule(item.analytics_post_ai_schedules);
            const [year, month, day] = schedule.date.split('-').map(Number);
            const postDate = new Date(year, month - 1, day, schedule.hours, schedule.minutes)
            return {
                title: item.title || item?.topic,
                start: postDate,
                backgroundColor: statusColor(item.posted),
                id: item.id,
            }
        })
        console.log("NEW DATA", newData)
        return newData;
    }

    function toNoCalendar(data) {
        let newData = data.filter((item, index) => {
            let schedule = getActiveSchedule(item.analytics_post_ai_schedules);
            return !schedule || schedule?.date === null;
        })
        newData = newData.map((item) => {
            return {
                title: item.title || item?.topic,
                backgroundColor: statusColor(item.posted),
                id: item.id,
            }
        })
        console.log("NEW NO DATA", newData)
        return newData;
    }

    const statusColor = (status) => {
        return status ? 'bg-success' : 'bg-warning';
    }

    const selectPost = (item) => {
        console.log("ITT", item)
        const selectedPost = surveys.find((p) => item.id === p.id)
        if (selectedPost) {
            // setPost(selectedPost)
            props.getPost(item.id)
        }
    }

    const handleEventReceive = async (info) => {
        console.log("DAG POST RECEIVE", info)
        const currentPost = surveys.find((it) => it.id === info.event.id);

        if (currentPost && !currentPost.posted) {
            if (window.confirm(`Do you want to schedule the post delivery for ${moment(info.event.startStr).format('LLL')}?`)) {
                let obj = {
                    id: currentPost.id,
                    platform: currentPost.platform,
                    date: moment(info.event.startStr).format('YYYY-MM-DD'),
                    hours: moment(info.event.startStr).format('H'),
                    minutes: moment(info.event.startStr).format('m')
                }
                await handleSubmitSchedule(false, obj)
            } else {
                info.revert();
            }
        } else {
            info.revert();
            toastr.info("Info", "You have already approved this post, so you cannot edit the posting time.")
        }
    }


    const handleEventDrop = async (info) => {
        // Update the event after it is dropped
        console.log("DAG POST", info)
        const currentPost = surveys.find((it) => it.id === info.event.id);

        if (currentPost && !currentPost.posted) {
            if (window.confirm(`Do you want to reschedule the post delivery for ${moment(info.event.startStr).format('LLL')}?`)) {
                let schedule = getActiveSchedule(currentPost.analytics_post_ai_schedules);
                let obj = {
                    id: currentPost.id,
                    scheduleId: schedule.id,
                    platform: currentPost.platform,
                    date: moment(info.event.startStr).format('YYYY-MM-DD'),
                    hours: moment(info.event.startStr).format('H'),
                    minutes: moment(info.event.startStr).format('m')
                }
                await handleSubmitSchedule(true, obj)
            } else {
                info.revert();
            }
        } else {
            info.revert();
            toastr.info("Info", "You have already approved this post, so you cannot edit the posting time.")
        }
    };

    const handleSubmitSchedule = async (edit = false, obj) => {
        console.log("SUBMIT");
        console.log('scheduleId:', obj?.scheduleId)

        //no way to edit a durable function running instance so we have to delete it and create a new one
        if (edit) {

            //need to delete an instance from function app
            await handleDelete(obj?.scheduleId, "edits");
            //need to delete from db
        }

        obj = {
            // days: this.state.days,
            hours: obj.hours,
            minutes: obj.minutes,
            timeZone: momentTz.tz.guess(),
            post_id: obj.id,
            platform: obj.platform,
            state: true,
            date: obj.date
            // teamId: teamId
        };
        console.log("SCHEDULE OBJ", obj);
        // this.setState({sending: true});

        let res = await setNewScheduleService(obj);
        // let res = {};
        console.log(res);
        if (res.id) {
            obj.analyticsPostAiId = obj.post_id;
            obj.id = res.id;
            // await this.props.addScheduleSlack(obj);
            await addSchedulePostAIService(obj);
            //const navigate = useNavigate();
            // this.setState({message: "Successfully scheduled"});
            // this.setState({success: true});
        }
        // this.setState({sending: false});
        props.getPostais(props.user.id);
        if (props.singlePostai) {
            props.getPost(obj.post_id)
        }
    }

    const handleDelete = async (scheduleId, reason) => {
        // this.setState({sending: true});
        // let res = await axios.post(`https://slack-survey-schedule.azurewebsites.net/runtime/webhooks/durabletask/instances/${scheduleId}/terminate?reason=${reason}&taskHub=slacksurveyschedule&connection=Storage&code=h6HQN7J805y4e80yV2m3svyP8/BqDukuuK4NBozo3ewLDh9Q4VCGeQ==`);
        let res = await deleteScheduleWebhookService(scheduleId, reason);
        console.log(res);
        if (res.status === 202) {
            console.log("Deleted an instance from azure");

            await props.deleteSchedule(scheduleId);
            // this.setState({sending: false});
            // this.setState({message: "Successfully deleted"})
            // this.setState({success: true});
        }
        if (reason !== "edits") {
            props.getPostais(props.user.id);
        }

    }


    /**external events*/

    useEffect(() => {
        let draggableEl = document.getElementById('external-events');
        setTimeout(() => {
            if (draggableEl) {
                new Draggable(draggableEl, {
                    itemSelector: '.nc-event',
                    eventData: function (eventEl) {
                        let id = eventEl.getAttribute('data-id');
                        let title = eventEl.getAttribute('data-title');
                        let backgroundColor = eventEl.getAttribute('data-bg');
                        return { title, id, backgroundColor };
                    }
                });
            }
        }, 300)
    }, [surveys]);



    return (
        <>
            {!props.singlePostai ? <Box sx={{width: '100%'}}>
            <div className="py-3">
                {postaiIntegration && <NavLink to='/chat-agpost' style={{textDecoration: 'none'}}>
                    <Button variant="contained">{t('New post')}</Button>
                </NavLink>}
            </div>
            <div className="my-3">
                {/*<SurveyTableSearch setFilter={handleChangeFilter}/>*/}
            <div className="container-fluid">
                {status === 'loading' ?
                    <div className="text-center col-12">
                        <Lottie options={defaultOptions} height={40} width={40}/>
                    </div>
                    :
                    <>
                        {/*stableSort(surveys, getComparator(order, orderBy), filter)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/}
                        {/*Object.entries(groupByDay(surveys)).map(([date, items], index) =>
                            <div className="row pt-4">
                                <div className="col-lg-12">
                                    {moment(date).isValid() ? <h3>
                                        <span className="text-secondary">{moment(date).format('D MMM') || ''}</span><b>{moment(date).format(' dddd')}</b>
                                    </h3> : <h3><b className="text-info">{date}</b></h3>}
                                </div>
                                <CustomScrollContent className="col-lg-12">
                                    <div className="row my-1 flex-nowrap">
                                        {items.map(item =>
                                            <div className="col-11 col-sm-8 col-md-4 col-lg-3">
                                                <SurveySlackCardItem key={index} item={item} onClickEdit={handleOpenEditPanel}/>
                                            </div>)}
                                    </div>
                                </CustomScrollContent>
                            </div>

                        )*/}

                        <div className="content-month" style={{
                            height: "79vh",
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            {/* External Events */}
                            <div id="external-events" style={{ marginRight: '20px', width: '30%' }}>
                                <p><strong>Drag to calendar to schedule a post</strong></p>
                                {toNoCalendar(surveys).map((event, index) => (
                                    <div className={`nc-event custom-event ${event.backgroundColor} p-2`}
                                        style={{
                                            margin: '5px 0',
                                            padding: '5px',
                                            height: '35px'
                                        }}
                                         key={index}
                                        onClick={(e) => selectPost(event)}
                                        data-id={event.id}
                                        data-title={event.title}
                                        data-bg={event.backgroundColor}>
                                        <Avatar className="post-icon-image" src={getPostInfo(event.id)?.imageThumbnail || getPostInfo(event.id)?.image || ''} sx={{ width: 20, height: 20 }}/>
                                        {/*<b className="mx-1">{timeText}</b>*/}
                                        {/*<b className="post-time">{moment(event.startStr).format("h:mm A")}</b>*/}
                                        <i className="post-title-1"><small>{event.title}</small></i>
                                        <PlatformIcon platform={getPostInfo(event.id)?.platform} currentPost={getPostInfo(event.id)} sz={20}/>
                                    </div>
                                ))}
                            </div>
                            <div className="" style={{width: '70%'}}>
                            <FullCalendar
                                plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                                initialView="timeGridWeek"
                                initialDate={null}
                                validRange={{
                                    start: null,
                                    end: null
                                }}
                                headerToolbar={{
                                    left: 'dayGridMonth,timeGridWeek prev,today,next',
                                    center: 'title',
                                    right: ""
                                }}
                                eventContent={(eventInfo) => {
                                    // console.log("ITEMMMMM", eventInfo)
                                    return <span className={`custom-event ${eventInfo.event.backgroundColor}`}
                                                 style={{
                                                     maxHeight: "100%"
                                                 }}>
                                    <Avatar className="post-icon-image" src={getPostInfo(eventInfo.event.id)?.imageThumbnail || getPostInfo(eventInfo.event.id)?.image || ''} sx={{ width: 20, height: 20 }}/>
                                        {getPostInfo(eventInfo.event.id)?.imageThumbnail && <img className="post-image" src={getPostInfo(eventInfo.event.id).imageThumbnail} alt=""/>}
                                        {/*<b className="mx-1">{eventInfo.timeText}</b>*/}
                                        <b className="post-time">{moment(eventInfo.event.startStr).format("h:mm A")}</b>
                                    <i className="post-title">{eventInfo.event.title}</i>
                                    <PlatformIcon platform={getPostInfo(eventInfo.event.id)?.platform} currentPost={getPostInfo(eventInfo.event.id)} sz={20}/>
                                </span>}}
                                eventClick={(e) => selectPost(e.event)}
                                eventColor="green"
                                events={toCalendar(surveys)}
                                editable={true}
                                droppable={true}
                                selectable={true}
                                selectMirror={true}
                                eventDrop={handleEventDrop}
                                eventReceive={handleEventReceive}
                                eventResize={(info) => info.revert()}
                                slotDuration="00:15:00"
                                slotHeight={500}
                                eventConstraint={{
                                    start: moment().format("YYYY-MM-DD"),
                                    end: ''
                                }}
                            />
                            </div>
                        </div>
                    </>
                }
            </div>
                {/*<TablePagination
                className="child-mb-0"
                rowsPerPageOptions={[8, 16, 24]}
                component="div"
                count={surveys.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}/>*/}
            </div>
            {/*<div className="py-3">
                <NavLink to='/survey-create' style={{textDecoration: 'none'}}>
                    <Button variant="contained">+ New Survey</Button>
                </NavLink>
            </div>*/}
            {
                isEditPanelOpen &&
                <EditPanel data={editData} onClickClose={handleCloseEditPanel}/>
            }
        </Box> :
                <PostShow/>}
        </>
    );
}

const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        singlePostai: state.postais.singlePostai,
        status: state.postais.status,
        user: state.users.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPost: (id) => dispatch(getSinglePostaiWithScheduleThunk(id)),
        getPostais: (userId) => dispatch(getAllPostaisWithScheduleThunk(userId)),
        deleteSchedule: (id) => dispatch(deleteScheduleThunk(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveySlackCard);
