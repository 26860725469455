import {
    Avatar,
    capitalize,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Chip,
    CircularProgress,
    IconButton, Switch,
    Tooltip,
    Typography
} from "@mui/material";
import {
    AccessTime,
    ArrowBack,
    Autorenew,
    Close,
    CloudDone,
    Done,
    Edit,
    HourglassEmpty, Refresh,
    Save, Telegram
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { toastr } from "react-redux-toastr";
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie'
import animationData from '../stars.json'
import {
    getSingleMonthPostService, getSinglePostaiService, sendPostNowService,
    setNewSinglePostScheduleService, updateImageMonthPostService,
    updateSingleMonthPostService, updateSinglePostaiService
} from "../../../../services/agent-post-ai.service";
import * as moment from "moment";
import momentTz from "moment-timezone";
import wordpressLogo from "../../../../assets/Wordpress_Blue_logo.png";
import linkedinLogo from "../../../../assets/Linkedin_blue_logo.png";
import { Stack } from '@fluentui/react';
import {usePostAiPlarformGuard} from "../../../../guards/post-ai-platform-guard";
import SlackDateTimePicker from "../schedule-slack/SlackDateTimePicker";
import DisplaySchedule from "../schedule-slack/DisplaySchedule";
import {connect} from "react-redux";
import {
    getAllPostaisWithScheduleThunk,
    getSinglePostaiWithScheduleThunk,
    updateSinglePostThunk
} from "../../../../redux/post-ai/post-ai.thunk";
import {updatePost} from "../../../../redux/post-ai/post-ai.actions";
import PlatformIcon from "../layouts/platform-icon";

const PostShow = (props) => {
    const [sending, setSending] = useState(false);
    // const [currentPost, setCurrentPost] = useState(null);
    const [itemDateTime, setItemDateTime] = useState(null);

    const [loading, setLoading] = useState(false);
    const [editable, setEditable] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [postAiPlatform, setPostAiPlatform] = useState(usePostAiPlarformGuard(props.currentPost?.platform));
    const [loadingSwImage, setLoadingSwImage] = useState(false);

    const titleRef = useRef(null);
    const contentRef = useRef(null);

    const navigate = useNavigate();
    const facebookLogo = 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg';

    useEffect(() => {
        // console.log("postTestData", postTestData)
        const func = (async () => {
            try {
                /*setLoading(true);
                console.log("POST 1: ", post)
                const p = await getSinglePostaiService(post.id)
                console.log("POST: ", p)
                setCurrentPost(p);
                // setPostAiPlatform(usePostAiPlarformGuard(p.platform))
                setItemDateTime(p?.postDate);
                setLoading(false)*/
            } catch (e) {
                setLoading(false)
                console.log("ERROR get posts", e)
                toastr.error("Error", "Something went wrong while trying to get the post, please try again in a few minutes")
            }
        })

        // func();

    }, [])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        hover: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    const handleSave = async () => {
        setLoading(true);

        /*const p = await updateSinglePostaiService({
            title: titleRef.current.innerText,
            content: contentRef.current.innerHTML
        }, post.id)*/
        await props.updateSinglePost({
            ...props.currentPost,
            title: titleRef.current.innerText,
            content: contentRef.current.innerHTML
        })
        props.getPostais(props.user.id);
        // console.log("POST MONTH: ", p)
        // setCurrentPost(p);
        setEditable(false)
        setLoading(false)
    }

    const handleOnOffImage = async (e) => {
        setLoadingSwImage(true);
        console.log("event in SW", e)
        await props.updateSinglePost({
            ...props.currentPost,
            extraInfo: {...props.currentPost.extraInfo, useImage: e.target.checked},
        })
        props.getPostais(props.user.id);
        setLoadingSwImage(false)
    }

    const sendPost = async () => {
        console.log("sending post")
        setSending(true)
        let obj = { post_id: props.currentPost.id }
        await sendPostNowService(obj)
        toastr.success("Success", `Content successfully published on ${capitalize(props.currentPost.platform)}`)
        setSending(false)
    }

    const handleNewImage = async () => {
        setLoadingImage(true);

        const p = await updateImageMonthPostService(props.currentPost.id)
        console.log("POST MONTH: ", p)
        if (p.newImageUrl) {
            props.updatePostState({...props.currentPost, image: p.newImageUrl})
        } else {
            toastr.warning("Error", "Something happened while trying to update the image, please try again.")
        }
        setLoadingImage(false)
    }

    const handleCancelEdition = () => {
        setEditable(false)
        titleRef.current.innerText = props.currentPost.title;
        contentRef.current.innerHTML = props.currentPost.content;

    }

    const getActiveSchedule = (schedules) => {
        // console.log('schedules:', schedules)
        if (schedules.length > 0) {
            for (let schedule of schedules) {
                if (schedule.state === true) {
                    return schedule;
                }
            }
        }
        return false;
    }
    const {currentPost} = props;
    return (
        <>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <IconButton color="primary" size="large" onClick={() => {
                        props.getPostais(props.user.id)
                        props.updatePostState(null)
                    }}>
                        <ArrowBack />
                    </IconButton>
                </div>
            </div>
            {loading ? <div className="d-flex justify-content-center text-center">
                <Stack.Item grow>
                    <p>
                        <div className="h-24">
                            <CircularProgress />
                            <div className="cubespinner">
                                <div className="face1">
                                    <Typography sx={{ fontSize: '15px', color: 'blue', fontWeight: '600', textAlign: 'center' }}>Hold on while we are generating content for you...</Typography>
                                </div>
                                <div className="face2">
                                    <Typography sx={{ fontSize: '15px', color: 'blue', fontWeight: '600', textAlign: 'center' }}>AI Agent is working on it...</Typography>
                                </div>
                                <div className="face3">
                                    <Typography sx={{ fontSize: '15px', color: 'blue', fontWeight: '600', textAlign: 'center' }}>Processing the data...</Typography>
                                </div>
                            </div>
                        </div>
                    </p>
                </Stack.Item>

            </div> :
                <Card className="col-md-8 offset-md-2 my-3">
                    <div className="mx-2 my-2 d-flex justify-content-between align-items-center">
                        <div className="me-1 d-flex align-items-center">
                            <div className="me-1">
                                <PlatformIcon platform={currentPost?.platform} currentPost={currentPost} sz={24}/>
                            </div>
                            {currentPost?.posted ?
                                <Chip label="Published" color="success" variant="outlined" icon={<CloudDone />} /> :
                                <Chip label="No Published" color="warning" variant="outlined"
                                    icon={<HourglassEmpty />} />}
                        </div>
                        <div className="d-flex">
                            {/**SEND POST*/}
                            {!editable && <Tooltip title={'Send Post'}>
                                <IconButton color="primary" onClick={() => sendPost()}>
                                    {sending ? <Lottie options={defaultOptions} height={40} width={40} /> : <Telegram />}
                                </IconButton>
                            </Tooltip>}

                            {/**UPDATE IMAGE*/}
                            {!editable && false &&
                                <Tooltip title={'New Image'}>
                                    <IconButton onClick={() => handleNewImage()} disabled={loadingImage}>
                                        <Refresh />
                                    </IconButton>
                                </Tooltip>}

                            {/**EDIT TIME POST*/}
                            {/*!editable && <EditTime post={currentPost} itemDateTime={itemDateTime} updateTime={setItemDateTime} />*/}
                            {!editable && postAiPlatform && (getActiveSchedule(currentPost["analytics_post_ai_schedules"]) === false ?
                                <SlackDateTimePicker surveyId={currentPost.id} post={currentPost} /> :
                                <div>
                                    <SlackDateTimePicker surveyId={currentPost.id} post={currentPost}
                                                         days={getActiveSchedule(currentPost["analytics_post_ai_schedules"]).days}
                                                         hours={getActiveSchedule(currentPost["analytics_post_ai_schedules"]).hours}
                                                         minutes={getActiveSchedule(currentPost["analytics_post_ai_schedules"]).minutes}
                                                         schedule={getActiveSchedule(currentPost["analytics_post_ai_schedules"])}
                                                         scheduleId={getActiveSchedule(currentPost["analytics_post_ai_schedules"]).id} />
                                </div>)}

                            {editable &&
                                <Tooltip title={'Save'}>
                                    <IconButton color="primary" onClick={() => handleSave()}>
                                        <Save />
                                    </IconButton>
                                </Tooltip>}

                            {editable ?
                                <Tooltip title={'Cancel Edition'}>
                                    <IconButton color="error" onClick={() => handleCancelEdition()}>
                                        <Close />
                                    </IconButton>
                                </Tooltip> :
                                <Tooltip title={'Edit Content'}>
                                    <IconButton onClick={() => setEditable(true)}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                    </div>
                    {currentPost?.image && !loadingImage &&
                    <div className="">
                        <div className="text-white position-absolute m-2" style={{
                            borderRadius: '17px',
                            background: 'rgba(255,255,255,0.58)'
                        }}>
                            {currentPost.platform !== 'instagram' && currentPost.platform !== 'tiktok' ?
                                loadingSwImage ? <CircularProgress size={30}/> :
                                        <Tooltip title="Show/hide image"><Switch aria-label='Switch Img'
                                                                                 checked={currentPost?.extraInfo !== null && "useImage" in currentPost?.extraInfo ? currentPost?.extraInfo?.useImage : true}
                                                                                 color="success"
                                                                                 onChange={(e) => handleOnOffImage(e)}/></Tooltip>
                                : null
                            }
                        </div>
                        <CardMedia
                        component="img"
                        height="320"
                        image={currentPost?.image}
                        alt="post image"
                        />
                    </div>}
                    {loadingImage &&
                        <div className="text-center">
                            <CircularProgress />
                            <p>Generating new image</p>
                        </div>
                    }
                    <CardHeader
                        title={<span contentEditable={editable}
                            ref={titleRef}
                            className={editable && 'border-edit border-success px-1 line-break'}
                            autoFocus={editable}
                            suppressContentEditableWarning={true}>
                            {currentPost?.title}
                        </span>}
                        subheader={<span>{currentPost["analytics_post_ai_schedules"] && getActiveSchedule(currentPost["analytics_post_ai_schedules"]) !== false && (
                            <DisplaySchedule
                                schedule={getActiveSchedule(currentPost["analytics_post_ai_schedules"])}
                            />
                        )}</span>} />
                    <CardContent>
                        <Typography gutterBottom component="div" color="text.secondary" className="mb-3">
                        </Typography>
                        <Typography variant="body1"
                            className={editable && 'border-edit border-success p-2 line-break'}
                            style={{
                                whiteSpace: 'pre-line'
                            }} contentEditable={editable}
                            ref={contentRef}
                            autoFocus={editable}
                            suppressContentEditableWarning={true}
                            dangerouslySetInnerHTML={{ __html: currentPost?.content }} />
                    </CardContent>
                    <CardActions className="justify-content-end">
                    </CardActions>
                </Card>}
        </>
    )
}
const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
        currentPost: state.postais.singlePostai,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPost: (id) => dispatch(getSinglePostaiWithScheduleThunk(id)),
        updateSinglePost: (post) => dispatch(updateSinglePostThunk(post)),
        updatePostState: (post) => dispatch(updatePost(post)),
        getPostais: (userId) => dispatch(getAllPostaisWithScheduleThunk(userId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostShow);
