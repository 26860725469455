import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const SelectTool = (props) => {
    const {tool, handleChangeTool, thinking} = props;
    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="tool-select">Tool</InputLabel>
            <Select
                labelId="tool-select"
                id="demo-select-small"
                value={tool}
                label="Age"
                disabled={thinking}
                onChange={handleChangeTool}
            >
                <MenuItem value="smart-goal">Smart Goal</MenuItem>
                <MenuItem value="smart-feedback">Smart Feedback</MenuItem>
            </Select>
        </FormControl>
    )
}

export default SelectTool;
