import {connect, useSelector} from "react-redux";

export const useAwsPostAIGuard = (route=null) => {
    const user = useSelector(state => state.users.user)
    const awsSubscription = user?.all_aws_post_ai_live_subscriptions;
    // console.log("AWS AWS", awsSubscription)
    /** manual pemission added for customers*/
    const manualPermission = user?.copilotAccess ? user?.copilotAccess['postAI'] : false;

    /**just for the team*/
    const specialPermission = user?.copilotAccess ? user?.copilotAccess['postAITeam'] : false;

    return awsSubscription && manualPermission ? !awsSubscription.subscription_expired : specialPermission;
}
