import * as React from 'react';
import {styled} from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import {AddToDrive, Cloud} from "@mui/icons-material";
import googleDriveLogo from "../../../../../assets/GoogleDriveLogo.png";
import oneDriveLogo from "../../../../../assets/OneDrive-Logo.png";

const StyledSpeedDial = styled(SpeedDial)(({theme}) => ({
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(10),
        right: theme.spacing(2),
    },
}));

const actions = [
    {icon: <img style={{width: '30px', height: '30px'}} src={googleDriveLogo} />, name: 'Google Drive'},
    {icon: <img style={{width: '40px'}} src={oneDriveLogo} />, name: 'Onedrive'},
];

function DriveChoose() {
    const [direction, setDirection] = React.useState('down');
    const [hidden, setHidden] = React.useState(false);

    const handleDirectionChange = (event) => {
        setDirection(event.target.value);
    };

    const handleHiddenChange = (event) => {
        setHidden(event.target.checked);
    };

    return (
        <StyledSpeedDial
            ariaLabel="Chat with"
            hidden={hidden}
            icon={<SpeedDialIcon/>}
            direction={direction}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}

                    icon={action.icon}
                    tooltipTitle={action.name}
                />
            ))}
        </StyledSpeedDial>
    );
}

export default DriveChoose;
