import {useEffect, useState} from "react";
import * as moment from 'moment';
import PostMonthItem from "./post-month-item";
import SinglePostShow from "../single-post-show/single-post-show";
import {connect} from "react-redux";
import {
    createPostMonthService,
    getAllMonthPostPerUserService,
    getAllMonthPostService,
    getSinglePlanMonthPostService, newTopicPostMonthService,
    updateSingleMonthPostService
} from "../../../../services/agent-post-ai.service";
import {Avatar, CircularProgress, styled} from "@mui/material";
import {useParams} from "react-router-dom";
import { toastr } from "react-redux-toastr";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from "@fullcalendar/timegrid";
import './post-month-show.css';
import PlatformIcon from "../layouts/platform-icon";
import momentTz from "moment-timezone";
import SinglePostCreate from "../single-post-create/single-post-create";

const CustomScrollContent = styled('div')(({theme}) => ({
    overflowX: "auto",
    [theme.breakpoints.up('xs')]: {
        maxWidth: "calc(100% - 5px)",
    },
    [theme.breakpoints.up('sm')]: {
        maxWidth: "calc(100% - 55px)",
    }

}));

const PostMonthShow = (props) => {
    const [posts, setPosts] = useState([])
    const [originalPosts, setOriginalPosts] = useState(null)
    const {post, setPost} = props;
    const [plan, setplan] = useState(null)
    const [loading, setLoading] = useState(false)
    const {plan_id} = useParams();

    /**to create post*/
    const [openCreate, setOpenCreate] = useState(false)
    const [topicCreate, setTopicCreate] = useState(null)
    const [dateCreate, setDateCreate] = useState(null)
    const [typeUserInfo, setTypeUserInfo] = useState(null)
    const [postIdCreated, setPostIdCreated] = useState(null)

    useEffect(() => {
        // console.log("postTestData", postTestData)
        const func = (async () => {
            try {
                setLoading(true)
                let posts;
                if (plan_id) {
                    posts = await getAllMonthPostService({
                        // analytics_user_id: props.user.id
                        plan_id
                    })
                    const p = await getSinglePlanMonthPostService(plan_id)
                    setplan(p)
                } else {
                    posts = await getAllMonthPostPerUserService(props.user.id)
                    setplan(null)
                }
                setLoading(false)
                console.log("POSTS MONTH: ", posts)

                // const newPosts = groupByDay(posts)
                const newPosts = toCalendar(posts)
                setOriginalPosts(posts)
                setPosts(newPosts);
                console.log("CALENDAR POSTS", newPosts)
            } catch (e) {
                setLoading(false)
                console.log("ERROR get posts", e)
                toastr.error(`Something went wrong, please reload the page to try again`);
            }
        })

        func();

    }, [post, plan_id, postIdCreated])

    function groupByDay(data) {
        console.log("data", data)
        return data.reduce((acc, item) => {
            const dateKey = item.postDate.split(' ')[0];
            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }
            acc[dateKey].push(item);
            return acc;
        }, {});
    }

    const statusColor = (status) => {
        switch (status) {
            case 'not-approved': return 'bg-warning';
            case 'approved': return 'bg-primary';
            case 'published': return 'bg-success';

        }
    }

    function toCalendar(data) {
        return data.map((item, index) => {
            const postDate = new Date(item.postDate)
            const endDate = new Date(postDate)
            endDate.setHours(postDate.getHours() + 1);
            return {
                title: item.title || item.topic,
                start: postDate,
                backgroundColor: statusColor(item.status),
                id: item.postId,
            }
        })
    }

    const getPostInfo = (postId) => {
        return originalPosts.find((post) => post?.postId === postId);
    }

    const selectPost = (item) => {
        console.log("ITT", item)
        const selectedPost = originalPosts.find((p) => item.id === p.postId)
        if (selectedPost) {
            setPost(selectedPost)
        }
    }

    const handleEventDrop = async (info) => {
        // Update the event after it is dropped
        console.log("DAG POST", info)
        const currentPost = originalPosts.find((it) => it.postId === info.event.id);

        if (currentPost && currentPost.status === 'not-approved') {
            if (window.confirm(`Do you want to reschedule the post delivery for ${moment(info.event.startStr).format('LLL')}?`)) {
                const updatedEvents = posts.map(event => {
                    if (event.id === info.event.id) {
                        return {
                            ...event,
                            start: info.event.startStr
                        };
                    }
                    return event;
                });
                setPosts(updatedEvents);

                const oPosts = originalPosts.map(post => {
                    if (post.postId === info.event.id) {
                        return {
                            ...post,
                            start: info.event.startStr
                        };
                    }
                    return post;
                });
                setOriginalPosts(oPosts)
                const p = await updateSingleMonthPostService({
                    postDate: moment(info.event.startStr).format('YYYY-MM-DD HH:mm:ss.SSS Z')
                }, info.event.id)
            } else {
                info.revert();
            }
        } else {
            info.revert();
            toastr.info("Info", "You have already approved this post, so you cannot edit the posting time.")
        }
    };

    const findAdjacentPosts = (posts, targetDate) => {
        const sortedPosts = posts.sort((a, b) => new Date(a.postDate) - new Date(b.postDate));

        let previousPost = null;
        let nextPost = null;

        for (let i = 0; i < sortedPosts.length; i++) {
            const postDate = new Date(sortedPosts[i].postDate);

            if (postDate < targetDate) {
                previousPost = sortedPosts[i];
            } else if (postDate > targetDate && !nextPost) {
                nextPost = sortedPosts[i];
                break;
            }
        }

        return { previousPost, nextPost };
    };

    const newPost = async (top) => {
        if (plan) {
            const post_id = await createPostMonthService({
                analytics_plan_id: plan.id,
                post_date: dateCreate,
                time_zone: momentTz.tz.guess(),
                topic: top,
                platform: plan.platform,
                type_user_info: typeUserInfo
            })
            console.log("DATE CLICK POST NEW TOPIC", post_id)
            setPostIdCreated(post_id)

        } else {
            toastr.warning("You need to select a plan to create a post")
        }

    }

    const generateTopicPost = async (e) => {
        if (window.confirm("Do you want to create a new post?")) {
            if (plan) {
                console.log("DATE CLICK ", e)
                const date = new Date(e.date)
                const {previousPost, nextPost} = findAdjacentPosts(originalPosts, date);
                let prevPostTopic = '';
                let nextPostTopic = '';
                setTypeUserInfo({})
                if (previousPost) {
                    prevPostTopic = previousPost.topic;
                    setTypeUserInfo(previousPost.typeUserInfo);
                }
                if (nextPost) {
                    nextPostTopic = nextPost.topic;
                    setTypeUserInfo(nextPost.typeUserInfo);
                }
                const datePost = moment(e.dateStr).utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS Z');
                setLoading(true)
                const topic = await newTopicPostMonthService({
                    prev_topic: prevPostTopic,
                    next_topic: nextPostTopic,
                    platform: plan.platform,
                })
                setLoading(false)
                console.log("DATE CLICK POST", prevPostTopic, nextPostTopic)
                console.log("DATE CLICK POST NEW TOPIC", topic)

                setTopicCreate(topic.topic)
                setOpenCreate(true)
                setDateCreate(datePost)

            } else {
                setLoading(false)
                toastr.warning("You need to select a plan to create a post")
            }
        }
    }

    return (
        <div className="container-fluid mt-2">
            <div className="w-100">
                {!post ? <div className="content-month" style={{
                        height: "87vh",
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}>

                        {loading && <div className="d-flex justify-content-center">
                            <CircularProgress/>
                        </div>}

                    { <FullCalendar
                            plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                            initialView="timeGridWeek"
                            initialDate={plan ? plan.startDate : null}
                            validRange={{
                                start: plan ? plan.startDate: null,
                                end: plan ? plan.endDate : null
                            }}
                            headerToolbar={{
                                left: 'dayGridMonth,timeGridWeek prev,today,next',
                                center: 'title',
                                right: ""
                            }}
                            eventContent={(eventInfo) => {
                                // console.log("ITEMMMMM", eventInfo)
                                return <span className={`custom-event ${eventInfo.event.backgroundColor}`}
                                style={{
                                    maxHeight: "100%"
                                }}>
                                <Avatar className="post-icon-image" src={getPostInfo(eventInfo.event.id)?.imageThumbnail || getPostInfo(eventInfo.event.id)?.image ||''} sx={{ width: 20, height: 20 }}/>
                                {getPostInfo(eventInfo.event.id)?.imageThumbnail && <img className="post-image" src={getPostInfo(eventInfo.event.id).imageThumbnail} alt=""/>}
                                    {/*<b className="mx-1">{eventInfo.timeText}</b>*/}
                                <b className="post-time">{moment(eventInfo.event.startStr).format("h:mm A")}</b>
                                <i className="post-title">{eventInfo.event.title}</i>
                                <PlatformIcon platform={getPostInfo(eventInfo.event.id)?.platform} currentPost={getPostInfo(eventInfo.event.id)} sz={20}/>
                            </span>}}
                            eventClick={(e) => selectPost(e.event)}
                            eventColor="green"
                            events={posts}
                            editable={true}
                            droppable={true}
                            selectable={true}
                            selectMirror={true}
                            eventDrop={handleEventDrop}
                            eventResize={(info) => info.revert()}
                            slotDuration="00:15:00"
                            slotHeight={500}
                            dateClick={generateTopicPost}
                        />}

                        {/*posts && Object.entries(posts).map(([date, items]) => (
                            <div className="row pt-4">
                                <div className="col-lg-12"><h3><span
                                    className="text-secondary">{moment(date).format('D MMM')}</span><b>{moment(date).format(' dddd')}</b>
                                </h3></div>
                                <CustomScrollContent className="col-lg-12">
                                    <div className="row my-1 flex-nowrap">
                                        {items.map(item =>
                                            <div className="col-11 col-sm-8 col-md-4 col-lg-3">
                                                <PostMonthItem item={item} setPost={setPost}/>
                                            </div>)}
                                    </div>
                                </CustomScrollContent>
                            </div>))*/}

                    </div> :
                    <SinglePostShow post={post} setPost={setPost}/>}
                {/*posts && Object.entries(posts).length === 0 &&
                    <h4 className="text-secondary">You don't have any plans yet</h4>*/}
                <SinglePostCreate open={openCreate} setOpen={setOpenCreate} topic={topicCreate} setTopic={setTopicCreate} date={dateCreate} newPost={newPost}/>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(PostMonthShow);
