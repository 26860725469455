import {Button, Card, CardContent, TextField, Typography} from "@mui/material";
import {useRef, useState} from "react";
import {getGrammarGoalStreamService, getGrammarFeedbackStreamService} from "../../../services/agent-hr.service";
import style from './smart-goals-live-index.module.css';
import {HtmlTooltip} from './helpers/html-tooltip';
import * as React from 'react';
import {toastr} from "react-redux-toastr";
import {applySegmentsToWords, getIndicesOf, strToJson} from "./helpers/text";
import CardResult from "./component/card-result";
import SelectTool from "./component/select-tool";
import CategoriesList from "./component/categories-list";
import FinalResult from "./component/final-result";
import SuccessAlertSap from "./component/success-alert-sap";

const SmartGoalsLiveIndex = () => {
    const textareaRef = useRef(null);

    const [jsonResponse, setJsonResponse] = useState({})
    const [result, setResult] = useState(null)
    const [finalResult, setFinalResult] = useState(null)
    const [content, setContent] = useState('')
    const [typingTimer, setTypingTimer] = useState(null)
    const [thinking, setThinking] = useState(false)
    const [tool, setTool] = useState('smart-goal')
    const [title, setTitle] = useState('Goal')

    const [successFeedback, setSuccessFeedback] = useState(false)

    const [categories, setCategories] = useState([
        {name: "Specific", color: "#1c83ea"},
        {name: "Measure", color: "#1dc410"},
        {name: "Achievable", color: "#ea4c1c"},
        {name: "Relevant", color: "#981cea"},
        {name: "Time-based", color: "#ea911c"},
    ])

    const [msgSAP, setMsgSAP] = useState(null);

    const changeInput = async (e) => {
        clearTimeout(typingTimer | undefined);
        if(e.target.value.length > 0 && e.target.value.length <= 1000) {
            setContent(e.target.value)
            setSuccessFeedback(false)
            calculateFinalResult(e.target.value, jsonResponse)

            // const nWords = e.target.value.split(' ').length;
            const nWords = e.target.value.length;

            // send analysis after 30 charts
            const fragment = nWords % 30;
            if (nWords > 0 && fragment === 0) {
                console.log("fragment", nWords, fragment)
                /// calculate
                await getResponse(e.target.value)
            } else {
                // send analysis after 3 sec inactivity
                const typing = setTimeout( () => {
                    console.log("time inactivity ", typing)
                    getResponse(e.target.value)
                }, 3000)
                setTypingTimer(typing)
            }
        }

        if(e.target.value === '') {
            setContent(e.target.value)
            setResult(null)
            setJsonResponse({})
            setSuccessFeedback(false)
            setFinalResult(null);
        }
    }

    const getResponse = async (con) => {
        let response;
        if (tool === 'smart-feedback') {
            setThinking(true);
            response = await getGrammarFeedbackStreamService(con, categories)
            setThinking(false);
        }

        if (tool === 'smart-goal') {
            setThinking(true);
            response = await getGrammarGoalStreamService(con)
            setThinking(false);
        }

        const reader = response.body?.getReader();
        const decoder = new TextDecoder();

        let completeResponse = "";
        while(true) {
            const { value, done: doneReading } = await reader.read();
            if (doneReading) break;

            let chunkValue = decoder.decode(value);
            completeResponse += chunkValue;

            // console.log("RES: ", completeResponse)
            const res = strToJson(completeResponse)
            console.log("RES", res)
            if (res.result) {
                if (res.result.length === categories.length) {
                    setJsonResponse({})
                    // const text = completeResponse.replace("<<", "").replace(">>", "");
                    setFinalResult(res.result);
                    // toastr.success("Good Feedback!", `${text}`)
                    setSuccessFeedback(true);
                    calculateFinalResult(con, {})
                }
            } else {
                setSuccessFeedback(false)
                setFinalResult(null);
                // const res = strToJson(completeResponse)
                console.log("JSON RES: ", res)

                // update if not empty
                if (res?.fixes) setJsonResponse(res);
                calculateFinalResult(con, res)
            }
        }

    }

    const calculateFinalResult = (con, res) => {
        // const res = strToJson(data);

        let finalResponse = [];

        for (const correction of res?.fixes || []) {
            // find all matching words with offset position
            const offsets = getIndicesOf(correction.original, con, true, finalResponse);

            if (offsets.length) {
                // create a new correction object with offset position
                for (const offset of offsets) {
                    const exist = finalResponse.find((item) => item.original === correction.original)
                    if (!exist) {
                        const catArray = [];
                        correction.categories.forEach((cat) => {
                            categories.forEach((c) => {
                                if (c.name === cat) {
                                    catArray.push(c);
                                }
                            })
                        })
                        finalResponse.push({
                            ...correction,
                            startOffset: offset,
                            endOffset: offset + correction.original.length,
                            length: correction.original.length,
                            categoryObj: catArray
                        });
                    } else {
                        finalResponse = finalResponse.map((item) => {
                            if (item.original === correction.original) {

                                const catArray = [];
                                correction.categories.forEach((cat) => {
                                    categories.forEach((c) => {
                                        if (c.name === cat) {
                                            catArray.push(c);
                                        }
                                    })
                                })

                                item.categoryObj = [...item.categoryObj, ...catArray]
                                item.category += correction.category;
                                item.suggestion += `\n\n${correction.suggestion}`;
                                item.corrected += `\n\n${correction.corrected}`;
                            }
                            return item;
                        })
                    }
                }
            }
        }
        // console.log("Final RES: ", finalResponse);

        /**TODO filter categories*/

        /** sort results by positions*/
        const sortedResults = finalResponse.sort((a, b) => a.startOffset - b.startOffset)
        const ranges = applySegmentsToWords(con, sortedResults);
        // console.log("Final RANGES: ", ranges);
        setResult(ranges);
        // setResponse(finalResponse);
    }

    const setCorrection = async (res) => {
        const newContent = content.replace(res.text, res.matches.corrected);
        console.log("correction: ", res, newContent)
        await changeInput({target:{value: newContent}})
    }

    const dismissResult = (i) => {
        const newResult = result.map((res, index) => {
            if (index === i)
                res.matches = null;
            return res;
        });
        setResult(newResult);
    }

    const handleChangeTool = (e) => {
        setTool(e.target.value)
        if (e.target.value === 'smart-feedback') {
            /*setCategories([
                {name: "Positive Reinforcement", color: "#1c83ea"},
                {name: "Constructive Suggestions", color: "#1dc410"},
                {name: "Emphasis on Teamwork", color: "#ea4c1c"},
                {name: "Specific Impact", color: "#981cea"},
            ])*/
            setCategories([
                {name: "Behavior", description: "Describe what has happened, what we have seen, describing their behavior through actions and leaving aside any option or subjectivity.", color: "#1c83ea"},
                {name: "Enquiry", description: "There must be a question to the employee about his point of view, to evaluate his situation.", color: "#1dc410"},
                {name: "Feelings", description: "Important to make the employee see what is happening, without judging, talking about our emotions.", color: "#ea4c1c"},
                {name: "Outcome", description: "Identify the consequences, which helps us measure the impact of the employee, so that they commit to being responsible for the change.", color: "#981cea"},
                {name: "Request", description: "There must be a request to the employee for a change, what we would like to happen next time in a concrete and explicit way.", color: "#ea911c"},
                {name: "Engagement", description: "It is essential that something is written that allows us to get the employee to accept and integrate the feedback, and to commit to changing their behavior.", color: "#206761"},
            ])
            setTitle("Feedback")
        }

        if (e.target.value === 'smart-goal') {
            setCategories([
                {name: "Specific", color: "#1c83ea"},
                {name: "Measure", color: "#1dc410"},
                {name: "Achievable", color: "#ea4c1c"},
                {name: "Relevant", color: "#981cea"},
                {name: "Time-based", color: "#ea911c"},
            ])
            setTitle("Goal")
        }

        setResult(null)
        setJsonResponse({})
        setSuccessFeedback(false)
        setFinalResult(null);
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3">
                    <div className="">
                        <SelectTool handleChangeTool={handleChangeTool} tool={tool} thinking={thinking}/>
                    </div>
                    <CategoriesList categories={categories}/>
                </div>
                <div className="col-md-9">
                    <h5>{`${title} Analysis`}</h5>

                    <div className="my-3 d-flex align-items-center">
                        <TextField
                            ref={textareaRef}
                            id="outlined-multiline-static"
                            className="w-100"
                            label={`Enter your ${title}`}
                            multiline
                            maxRows={4}
                            value={content}
                            onChange={(e) => changeInput(e)}
                            helperText={`${content.length || 0}/1000`}
                        />
                    </div>

                    <SuccessAlertSap msgSAP={msgSAP} setMsgSAP={(val) => setMsgSAP(val)}/>

                    <CardResult thinking={thinking} result={result} success={successFeedback} setCorrection={setCorrection} dismissResult={dismissResult} tool={tool} content={content} title={title} setMsgSAP={(val) => setMsgSAP(val)}/>
                    {/*<Button variant="contained" onClick={() => getCoord()}>GENERATE</Button>*/}
                </div>

            </div>
            <div className="">
                {finalResult && <FinalResult finalResult={finalResult} success={successFeedback} categories={categories}/>}
            </div>
        </div>)
}

export default SmartGoalsLiveIndex;
