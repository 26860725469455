import axios from "axios";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
} from "docx";
import { downloadExcel } from "react-export-table-to-excel";
import pptxgen from "pptxgenjs";
// import { getBodyEmail, sendEmailService } from "../services/agent-hr.service";
import { Chart as ChartJS } from "chart.js";

function textWithoutCitations(parsedTextWithCitations) {
  let textFragments = parsedTextWithCitations.filter(
    (value) => value.type === "text-fragment"
  );
  if (textFragments.length === 0) return "";
  const textWithoutCitations = textFragments
    .map((value) => value.data)
    .reduce((previousValue, currentValue) => previousValue + currentValue);
  return textWithoutCitations;
}

function chartjsAsync(ctx, { type, data, options }) {
  return new Promise((resolve) => {
    const chart = new ChartJS(ctx, {
      type: type,
      data: data,
      options: {
        ...options,
        animation: {
          onComplete: () => {
            resolve(chart);
          },
        },
      },
    });
  });
}

export async function exportAnswerToPdf(parsedAnswer, answer) {
  const jsonChart = (
    await axios.post(
      `${process.env.REACT_APP_PPTXGEN_BACKEND}/api/generateJsonChart`,
      {
        question: "",
        answer: answer,
      }
    )
  ).data;
  const chartCanvas = document.createElement("canvas");
  const ctx = chartCanvas.getContext("2d");
  chartCanvas.setAttribute("width", "500");
  chartCanvas.setAttribute("height", "500");
  chartCanvas.style.display = "none";
  document.body.append(chartCanvas);
  const chart = await chartjsAsync(ctx, {
    type: "bar",
    data: {
      labels: jsonChart.labels,
      datasets: jsonChart.datasets,
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  });
  const chartBase64 = chart.toBase64Image("image/png", 1);
  const doc = new jsPDF({ unit: "px" });
  doc.setFontSize(10);
  let y = 15;
  for (const value of parsedAnswer) {
    if (value.type === "text") {
      const text = textWithoutCitations(value.data);
      const splittedText = doc.splitTextToSize(text, 400);
      doc.text(splittedText, 10, y);
      y += splittedText.length * 10;
    }
    if (value.type === "table") {
      doc.autoTable({
        head: [value.data[0]],
        body: value.data.slice(1),
        startY: y,
      });
      y += value.data.length * 16 + 16;
    }
  }
  doc.addImage(chartBase64, "PNG", 12, y, 380, 250);
  doc.save("document");
}

export async function exportAnswerToPdfBlob(parsedAnswer, answer) {
  if (parsedAnswer.length > 0) {
    const jsonChart = (
      await axios.post(
        `${process.env.REACT_APP_PPTXGEN_BACKEND}/api/generateJsonChart`,
        {
          question: "",
          answer: answer,
        }
      )
    ).data;
    const chartCanvas = document.createElement("canvas");
    const ctx = chartCanvas.getContext("2d");
    chartCanvas.setAttribute("width", "400");
    chartCanvas.setAttribute("height", "400");
    chartCanvas.style.display = "none";
    document.body.append(chartCanvas);
    const chart = await chartjsAsync(ctx, {
      type: "bar",
      data: {
        labels: jsonChart.labels,
        datasets: jsonChart.datasets,
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
    const chartBase64 = chart.toBase64Image("image/png", 1);
    const doc = new jsPDF({ unit: "px" });
    doc.setFontSize(10);
    let y = 15;
    for (const value of parsedAnswer) {
      if (value.type === "text") {
        const text = textWithoutCitations(value.data);
        const splittedText = doc.splitTextToSize(text, 400);
        doc.text(splittedText, 10, y);
        y += splittedText.length * 10;
      }
      if (value.type === "table") {
        doc.autoTable({
          head: [value.data[0]],
          body: value.data.slice(1),
          startY: y,
        });
        y += value.data.length * 16 + 16;
      }
    }
    doc.addImage(chartBase64, "PNG", 10, y, 280, 150);
    return doc.output("datauristring");
  } else {
    return null;
  }
}

export async function exportAnswerToDocument(parsedAnswer) {
  const data = {
    sections: [
      {
        properties: {},
        children: [],
      },
    ],
  };
  for (const value of parsedAnswer) {
    if (value.type === "text") {
      data.sections[0].children.push(
        new Paragraph({
          // children: [new TextRun(value.data)],
          children: [new TextRun(textWithoutCitations(value.data))],
        })
      );
    }
    if (value.type === "table") {
      data.sections[0].children.push(
        new Table({
          rows: value.data.map((value) => {
            return new TableRow({
              children: value.map((value) => {
                return new TableCell({
                  children: [new Paragraph(value)],
                });
              }),
            });
          }),
        })
      );
    }
    data.sections[0].children.push(
      new Paragraph({
        children: [new TextRun("\n\n")],
      })
    );
  }
  // remove the last linebreaks
  data.sections[0].children.pop();
  console.log("Word data:", data);
  const doc = new Document(data);
  await Packer.toBlob(doc).then((blob) => {
    console.log(blob);
    saveAs(blob, "example.docx");
    console.log("Document created successfully");
  });
}

export async function exportTableToSpreadsheet(table) {
  const header = table[0];
  const body = table.slice(1);
  downloadExcel({
    fileName: "document1",
    sheet: "sheet1",
    tablePayload: {
      header,
      body: body,
    },
  });
}

export async function exportToSlides(jsonSlides) {
  console.log("JSON SLIDES:", jsonSlides);
  let pptx = new pptxgen();
  for (const jsonSlide of jsonSlides) {
    let slide = pptx.addSlide();
    slide.addText(jsonSlide.title, {
      x: 0.9,
      y: 0.9,
      fontSize: 32,
      color: pptx.SchemeColor.text1,
    });
    slide.background = { color: "e8ebfa", transparency: 50 };
    /*
    if (jsonSlide.image !== "") {
      const imageUrl = (await gis(jsonSlide.image)).slice(0, 10)[0].url;
      slide.addImage({ path: imageUrl });
    }
    */
    if (jsonSlide.content !== undefined) {
      slide.addText(jsonSlide.content, {
        x: 0.5,
        y: 2,
        fontSize: 16,
      });
    }
    if (jsonSlide.chart !== undefined) {
      slide.addChart(pptx.ChartType.line, jsonSlide.chart.data, {
        x: 1,
        y: 1,
        w: 8,
        h: 4,
      });
    }
  }
  await pptx.writeFile();
}

/*
export async function exportToEmail(
  email,
  question,
  answer,
  insights,
  parsedAnswer
) {
  const body = await getBodyEmail(question, { answer: answer }, insights);
  const htmlContent = `<div style="white-space: pre-line">${body}</div>`;
  let pbi = null;
  pbi = await exportAnswerToPdfBlob(parsedAnswer);
  pbi = pbi.split("base64,")[1];
  await sendEmailService({
    email,
    senderName: "Moodbit AI Copilot",
    subject: "Moodbit AI Copilot Sent You an Email",
    body: htmlContent,
    pbi,
  });
}
*/