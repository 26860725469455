import {connect, useSelector} from "react-redux";

export const useAwsGuard = (route=null) => {
    const user = useSelector(state => state.users.user)
    const awsSubscription = user?.all_aws_live_subscriptions;
    console.log("AWS AWS", awsSubscription)

    /**just for the team*/
    const specialPermission = user?.copilotAccess ? user?.copilotAccess['copilotTeam'] : false;

    return awsSubscription && user.whitelisted ? !awsSubscription.subscription_expired : specialPermission;
}
