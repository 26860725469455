import {List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {FiberManualRecord} from "@mui/icons-material";

const CategoriesList = (props) => {
    const {categories} = props;
    return (
        <>
            <List dense={true}>
                {categories.map((cat, index) =>
                    <ListItem key={index}>
                        <ListItemIcon>
                            <FiberManualRecord fontSize="small" style={{
                                color: cat.color
                            }}/>
                        </ListItemIcon>
                        <ListItemText
                            primary={cat.name}
                        />
                    </ListItem>,
                )}
            </List>
        </>
    )
}

export default CategoriesList;
