import {Box, styled, Tooltip, tooltipClasses} from "@mui/material";
import * as React from "react";

export const HtmlTooltip = styled(({ className, ...props }) => {
    const positionRef = React.useRef({
        x: 0,
        y: 0,
    });
    const popperRef = React.useRef(null);
    const areaRef = React.useRef(null);

    const handleMouseMove = (event) => {
        positionRef.current = { x: event.clientX, y: event.clientY };

        if (popperRef.current != null) {
            popperRef.current.update();
        }
    };

    return (
        <Tooltip {...props}
                 PopperProps={{
                     popperRef,
                     anchorEl: {
                         getBoundingClientRect: () => {
                             return new DOMRect(
                                 positionRef.current.x,
                                 // areaRef.current.getBoundingClientRect().y + 10,
                                 positionRef.current.y + 3,
                                 0,
                                 0,
                             );
                         },
                     },
                 }}
                 classes={{ popper: className }} >
            <Box component="span" ref={areaRef}
                onMouseMove={handleMouseMove}>
                {props.children}
            </Box>
        </Tooltip>
    )
})(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 370,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));
