import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    IconButton,
    Tooltip,
    Typography
} from "@mui/material";
import style from "../smart-goals-live-index.module.css";
import {HtmlTooltip} from "../helpers/html-tooltip";
import * as React from "react";
import {ContentCopy, Publish} from "@mui/icons-material";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toastr} from "react-redux-toastr";
import {useState} from "react";
import {setFeedbackSAPAuthService, setSAPAuthService} from "../../../../services/agent-hr.service";

const gradBg = (categories) => {
    let grad = categories[0]?.color || "#3c3f41";
    if (categories.length > 1) {
        const raz = Math.floor(100 / categories.length);
        let percent = 0;
        grad = "linear-gradient(90deg";
        categories.forEach((cat) => {
            percent += raz;
            grad += `, ${cat.color || "#3c3f41"} ${percent}%`
        })
        grad += ")"
    }
    console.log("GRAD", grad)
    return grad;
}

const CardResult = (props) => {
    const {thinking, result, success, setCorrection, dismissResult, tool, content, title, setMsgSAP} = props
    const [loadingSAP, setLoadingSAP] = useState(false);
    // const [msgSAP, setMsgSAP] = useState(null);

    const submitGoalToSAP = async () => {
        console.log("submit to SAP")

        try {
            let {d} = await setSAPAuthService({"message": content});
            console.log("SAP AUTH ", d)
            return d;
        } catch (e) {
            return e
        }
    }

    const submitFeedbackToSAP = async () => {
        console.log("submit to SAP")
        try {
            let res = await setFeedbackSAPAuthService({"message": content});

            console.log("SAP AUTH ", res)

            return res;
        } catch (e) {
            return e;
        }

    }

    const submitToSAP = async () => {
        if (window.confirm('Are you sure to send this information? This operation will create new records in your SAP environment')) {

            let response = null;
            setLoadingSAP(true)
            if (tool === 'smart-goal') {
                response = await submitGoalToSAP()
                setMsgSAP({
                    title: 'Success: Goal Saved successfully',
                    subtitle: 'These are the details of the registered goal',
                    details: [
                        {
                            title: 'User Id',
                            description: `${response.useId}`
                        },
                        {
                            title: 'Name',
                            description: `${response.name}`
                        },
                        {
                            title: 'Metric',
                            description: `${response.metric}`
                        },
                        {
                            title: 'Start Date',
                            description: `${response.start}`
                        },
                        {
                            title: 'Due Date',
                            description: `${response.due}`
                        }
                    ]
                })
                toastr.success('Success Goal Saved successfully')
            }
            if (tool === 'smart-feedback') {
                response = await submitFeedbackToSAP()
                const res = {
                    title: 'Success: Feedback Saved successfully',
                    subtitle: 'These are the details of the registered feedback',
                    details: [
                        {
                            title: 'Subject User UUID',
                            description: `${response.subjectUserAssignmentUUID}`
                        },
                        {
                            title: 'Sender User UUID',
                            description: `${response.senderUserAssignmentUUID}`
                        },
                        {
                            title: 'Topic',
                            description: `${response.topic}`
                        },
                    ]
                };

                if (response.question1 !== null && response.answer1 !== null) {
                    res.details.push({
                        title: "Question 1",
                        description: `${response.question1}`
                    })
                    res.details.push({
                        title: "Answer 1",
                        description: `${response.answer1}`
                    })
                }

                if (response.question2 !== null && response.answer2 !== null) {
                    res.details.push({
                        title: "Question 2",
                        description: `${response.question2}`
                    })
                    res.details.push({
                        title: "Answer 2",
                        description: `${response.answer2}`
                    })
                }

                if (response.question3 !== null && response.answer3 !== null) {
                    res.details.push({
                        title: "Question 3",
                        description: `${response.question3}`
                    })
                    res.details.push({
                        title: "Answer 3",
                        description: `${response.answer3}`
                    })
                }

                setMsgSAP(res)

                toastr.success('Success Feedback Saved successfully')
            }
            setLoadingSAP(false);
        }
    }

    return (
        <>
            {(thinking || result) && <Card className="my-2" style={{
                background: success ? '#60bb71' : "#fff",
                color: success ? '#fff' : "#000",
            }}>
                {success && <CardHeader title={`Final ${title}`} action={
                    <>
                        {!loadingSAP ? <Tooltip title="Save in SAP">
                            <IconButton
                                onClick={() => submitToSAP()}>
                                <Publish/>
                            </IconButton>
                        </Tooltip> : <CircularProgress/>}
                        <CopyToClipboard text={content}
                                         onCopy={() => toastr.success('', "Successfully copied content")}>
                            <Tooltip title="Copy Result">
                                <IconButton>
                                    <ContentCopy fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </CopyToClipboard>
                    </>
                }/>}
                <CardContent>
                    <p className={style.base}>
                        {result?.map((r, i) => (
                            r.matches ?
                                <HtmlTooltip
                                    key={i}
                                    title={
                                        <React.Fragment>
                                            <div style={{maxHeight: '350px', overflowY: 'auto'}}>
                                                {r.matches.categoryObj.map((cat, index) => <Typography key={index} style={{
                                                    color: cat.color,
                                                }} className="d-inline me-2">{cat.name}</Typography>)}
                                                <br/>
                                                {r.matches.suggestion}
                                                <br/>
                                                <br/>
                                                <b>Suggestion</b>
                                                <br/>
                                                <em>{r.matches.corrected}</em>
                                            </div>
                                            <div className="my-2">
                                                <Button variant="contained" size="small" color="primary" onClick={() => setCorrection(r)}>Accept</Button>
                                                <Button variant="contained" size="small" color="inherit" onClick={() => dismissResult(i)}>Dismiss</Button>
                                            </div>
                                        </React.Fragment>
                                    }>
                                         <span className={style.match} style={{
                                             // background: r.matches.categoryObj[0].color}}>
                                             background: gradBg(r.matches.categoryObj)}}>
                                            {r.text}
                                         </span>
                                </HtmlTooltip>
                                : <span key={i}>{r.text}</span>
                        ))}
                        {thinking && <span className="text-primary">{' Thinking...'}</span>}
                    </p>
                </CardContent>

            </Card>}
        </>
    )
}

export default CardResult;
