import {setContainer, setIndex, setIndexObj} from "../../../../../redux/agent-hr/agent-hr.actions";
import {connect} from "react-redux";
import {Badge, Tooltip} from "@mui/material";
import googleDriveLogo from "../../../../../assets/GoogleDriveLogo.png";
import oneDriveLogo from "../../../../../assets/OneDrive-Logo.png";

const DriveUsed = (props) => {
    return (
        <>
            {props.indexObj && props.indexObj.drive === 'googledrive' &&
                <Tooltip title="Connected to Google Drive">
                    <Badge color="success" variant="dot">
                        <img style={{width: '25px', height: '25px'}} src={googleDriveLogo} />
                    </Badge>
                </Tooltip>}

            {props.indexObj && props.indexObj.drive === 'onedrive' &&
                <Tooltip title="Connected to OneDrive">
                    <Badge color="success" variant="dot">
                        <img style={{width: '34px'}} src={oneDriveLogo} />
                    </Badge>
                </Tooltip>}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        indexName: state.agentHrs.index,
        indexObj: state.agentHrs.indexObj
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIndexName: (index) => dispatch(setIndex(index)),
        setContainer: (container) => dispatch(setContainer(container)),
        setIndex: (obj) => dispatch(setIndexObj(obj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriveUsed);
