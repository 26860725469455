import {
    Avatar,
    Box,
    capitalize,
    Card, CardActions,
    CardContent,
    CardHeader, CardMedia, Chip,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography
} from "@mui/material";
import {AccessTime, Approval, CloudDone, Done, HourglassEmpty, Telegram, Topic, Visibility} from "@mui/icons-material";
import React, {useState} from "react";
import './post-month.css';
import {toastr} from "react-redux-toastr";
import {
    addSchedulePostAIService,
    sendPostNowService,
    setNewScheduleService, setNewSinglePostScheduleService, updateSingleMonthPostService
} from "../../../../services/agent-post-ai.service";
import Lottie from 'react-lottie'
import animationData from '../stars.json';
import * as moment from "moment";
import {truncateString} from "../../../../helpers/text";
import momentTz from "moment-timezone";
import wordpressLogo from '../../../../assets/Wordpress_Blue_logo.png';
import linkedinLogo from '../../../../assets/Linkedin_blue_logo.png';
import instagramLogo from "../../../../assets/Instagram_icon.png";
import tiktokLogo from "../../../../assets/TikTokLogo.png";
import twitterLogo from "../../../../assets/Twitter_icon.png";
import SlackDateTimePicker from "../../agent-post/schedule-slack/SlackDateTimePicker";
import EditTime from "../edit-time/edit-time";
import {connect} from "react-redux";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const createOptions = (name, routerLink, translation) => {
    return {
        name,
        routerLink,
        translation
    }
}

const PostMonthItem = (props) => {
    const facebookLogo = 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg';

    const options = [
        createOptions('Delete', '', 'delete'),
    ]

    const {item, setPost} = props;
    const [itemDateTime, setItemDateTime] = useState(item?.postDate);
    const [itemStatus, setItemStatus] = useState(item?.status);
    const [anchorEl, setAnchorEl] = useState(null);
    const [status, setStatus] = useState(item.active);
    const [open, setOpen] = useState(false);
    const [sending, setSending] = useState(false);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseMenu = (option = '') => {
        setAnchorEl(null);
    }

    const getActiveSchedule = (schedules) => {
        // console.log('schedules:', schedules)
        if (schedules.length > 0) {
            for (let schedule of schedules) {
                if (schedule.state === true) {
                    return schedule;
                }
            }
        }
        return false;
    }
    const sendPost = async () => {
        console.log("sending post")
        setSending(true)
        let obj = {post_id: item.id}
        await sendPostNowService(obj)
        toastr.success("Success", `Content successfully published on ${capitalize(item.platform)}`)
        setSending(false)
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        hover: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    const handleSubmit = async () => {
        console.log("SUBMIT");
        // console.log('scheduleId:', this.props.scheduleId)

            //no way to edit a durable function running instance so we have to delete it and create a new one
            /*if (this.state.type === "EDIT") {

                //need to delete an instance from function app
                await this.handleDelete(this.props.scheduleId, "edits");
                //need to delete from db
            }*/
        let myTime = moment(itemDateTime).format('HH:mm');
        console.log('TIME', myTime);
        let str = myTime.split(':');

        if (window.confirm(`Are you sure you have scheduled this Post to be sent at ${myTime} on the ${item?.platform.toUpperCase()} platform?`)) {

            // let teamId = this.props.selectedTeam;

            let obj = {
                day: moment(itemDateTime).format('D'),
                month: moment(itemDateTime).format('M'),
                year: moment(itemDateTime).format('YYYY'),
                hours: str[0],
                minutes: str[1],
                timeZone: momentTz.tz.guess(),
                // surveyId: this.props.surveyId,
                post_id: item.postId,
                platform: item.platform,
                // post: this.props.post,
                // credentials: this.props.credentials,
                // state: true,
                // teamId: teamId
            };
            console.log("SCHEDULE OBJ", obj);

            // await this.props.getUserSlack(this.context.accounts[0].username);

            // let res = await axios.post("https://slack-survey-schedule.azurewebsites.net/api/orchestrators/NewSchedule", obj);
            let res = await setNewSinglePostScheduleService(obj);
            // let res = {};
            console.log(res);
            if (res.id) {
                // obj.analyticsPostAiId = this.props.post.id;
                // obj.id = res.id;

                // await this.props.addScheduleSlack(obj);
                // await addSchedulePostAIService(obj);
                await updateSingleMonthPostService({status: 'approved'}, item.postId)
                setItemStatus('approved');
                //const navigate = useNavigate();
                // this.setState({message: "Successfully scheduled"});
                // this.setState({success: true});
                // navigate('/stored-post');
                toastr.success("Success", "Post Approved")
            }
            this.setState({sending: false});
            this.props.getAllPostaisWithSchedule(this.props.user.id);
        }
    }

    console.log('INFOO:', item )
    console.log('INFOO2:', props.user )

    return (
        <div className="h-100">
            <Card className="h-100 position-relative">
                <div className="mx-2 my-2 d-flex justify-content-between align-items-center">
                    <span className="me-1">
                        { item.platform === 'wordpress' && <Tooltip title={props.user.analytics_wordpress_integrations[0].blogUrl}><Avatar sx={{ width: 24, height: 24 }} alt="wordpress" src={wordpressLogo} /></Tooltip>}
                        { item.platform === 'facebook' && <Tooltip title={props.user.analytics_facebook_integrations[0]?.email}><Avatar sx={{ width: 24, height: 24 }} alt="facebook" src={facebookLogo} /></Tooltip>}
                        { item.platform === 'linkedin' && <Tooltip title={props.user.analytics_linkedin_integrations[0].vanityName}><Avatar sx={{ width: 24, height: 24 }} alt="linkedin" src={linkedinLogo} /></Tooltip>}
                        { item.platform === 'tiktok' && <Tooltip title=""><Avatar sx={{ width: 24, height: 24 }} alt="tiktok" src={tiktokLogo} /></Tooltip>}
                        { item.platform === 'instagram' && <Tooltip title=""><Avatar sx={{ width: 24, height: 24 }} alt="instagram" src={instagramLogo} /></Tooltip>}
                        { item.platform === 'twitter' && <Tooltip title=""><Avatar sx={{ width: 24, height: 24, background: "#000" }} alt="twitter" src={twitterLogo} /></Tooltip>}
                    </span>
                    { itemStatus === 'approved' && <Chip size= "small" label="Approved" color="primary" variant="outlined" icon={<Done/>}/>}
                    { itemStatus === 'published' && <Chip size= "small" label="Published" color="success" variant="outlined"  icon={<CloudDone/>}/>}
                    { itemStatus === 'not-approved' && <Chip size= "small" label="No Published" color="warning" variant="outlined" icon={<HourglassEmpty/>}/>}
                </div>
                {item.image && <CardMedia
                    component="img"
                    height="100"
                    image={item.image}
                    alt="post image"
                />}
                <CardHeader title={<b>{item.title || ''}</b>}
                            subheader={<span>
                                <AccessTime/>
                                <b>{moment(itemDateTime).format('h:mm A')}</b>
                            </span>}/>
                <CardContent className="mb-4">
                    {!item.image && <Typography gutterBottom component="div" color="text.secondary">
                
                        <Typography sx={{bgcolor:'#F6F193', borderRadius:'7px',fontWeight:'600', fontSize:'11px', marginBottom:'20px', padding:'7px'}}><WarningAmberIcon sx={{color:'orange'}}/> Your post is being generated, please reload the page in a few minutes...</Typography>
                        
                        <Topic/> <b>{item.topic}</b>
                    </Typography>}
                    <Typography component="small" color="text.secondary" dangerouslySetInnerHTML={{__html: truncateString(item.content, 100)}}/>
                </CardContent>
                <CardActions className="justify-content-end w-100 position-absolute bottom-0">
                    { itemStatus === 'not-approved' && <Tooltip title={'Approve'}>
                        <IconButton size="small" onClick={() => handleSubmit()} disabled={!item.image}>
                            {sending ? <Lottie options={defaultOptions} height={40} width={40}/> : <Done/>}
                        </IconButton>
                    </Tooltip>}
                    { itemStatus === 'not-approved' && <EditTime post={item} itemDateTime={itemDateTime} updateTime={setItemDateTime}/>}
                    <Tooltip title={'See post'}>
                        <IconButton size="small" onClick={() => setPost(item)}>
                             <Visibility/>
                        </IconButton>
                    </Tooltip>
                </CardActions>
            </Card>
            {/*<div className="card-action-bottom border-top">
                <div className="d-flex justify-content-evenly animateHover">
                    <Tooltip title={'Send post'}>
                        <IconButton onClick={() => sendPost()}>
                            {sending ? <Lottie options={defaultOptions} height={40} width={40}/> : <Telegram/>}
                        </IconButton>
                    </Tooltip>
                </div>
            </div>*/}
        </div>
    )
}

const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(PostMonthItem)
